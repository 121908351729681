import { styled } from "styled-components";
import { getThemes } from "./Themes";

const ChoicePopupCanvas = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 25px; */
  background-color: ${getThemes().softDarkGlass};
  width: 650px;
  max-width: 90%;
  max-height: 90%;
  border-radius: 25px;
  overflow: hidden;
  overflow-y: auto;

  z-index: 120;

  h2{
    font-size: 1.5rem;
    font-weight: 600;
    color: ${getThemes().light};
    font-family: ${getThemes().titleFont};
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span{
      opacity: 0.5;
    }
  }

  p{
    width: 100%;
    margin-bottom: 10px;
    font-size: 1rem;
    text-align: center;
    color: ${getThemes().light};
    font-family: ${getThemes().currentFont};
    opacity: 0.8;
  }

  small{
    width: 100%;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: ${getThemes().dangerColor};
    font-family: ${getThemes().currentFont};
  }
`;




const CanvasPopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${getThemes().softDarkGlass};
  width: 650px;
  max-width: 90%;
  max-height: 90%;
  border-radius: 25px;
  overflow: hidden;
  overflow-y: auto;

  z-index: 120;

  .nk-cp-title-box{
    width: 100%;
    height: 70px;
    padding: 0 25px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${getThemes().softDark};
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 5;
    border-bottom: 1px solid ${getThemes().layerCardStroke};

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      background-color: ${getThemes().softDarkAlpha};
    }

    h2{
      font-size: 1.5rem;
      color: ${getThemes().light};
      font-family: ${getThemes().titleFont};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span{
        opacity: 0.25;
      }
    }
  }

  .nk-cp-container{
    position: relative;
  }

  // remove scroll bar on all browsers
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export {
  ChoicePopupCanvas,
  CanvasPopupContainer,
};