import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import { styled } from 'styled-components';
import { getThemes } from '../styles/Themes';
import Home from '../pages/Home';
import { AppContext } from './AppContext';
import Environment from '../pages/Environment';
import AdminUsers from '../pages/AdminUsers';
import Helps from '../pages/Helps';
import Integrations from '../pages/Integrations';
import Maintenance from '../pages/Maintenance';
import Comments from '../pages/Comments';
import Profile from '../pages/Profile';
import NewEnv from '../pages/environments/NewEnv';
import Logout from '../pages/Logout';



const Main = styled.main`
  width: calc(100% - 260px);
  margin-left: 260px;
  height: 100%;
  padding-top: ${props => props.$less ? "60px" : "0"};
  scroll-behavior: none;
`;

const AnimatedRoutes = (props) => {
  const app = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    app.setGhostFilledContent(null);
    app.setGhostContent(null);
  }, [location]);

  return (
    <AnimatePresence>
      <Main>
        <Routes location={location} key={location.pathname}>
          <Route exact path="*" element={<Navigate to="/dashboard" replace={true} />} />
          <Route exact path="/dashboard" element={<Home />} />

          <Route exact path="/environments" element={<Environment />} />
          <Route exact path="/environments/create" element={<NewEnv />} />
          <Route exact path="/environments/:id" element={null} />

          <Route exact path="/users" element={<AdminUsers />} />
          <Route exact path="/helps" element={<Helps />} />
          <Route exact path="/integrations" element={<Integrations />} />
          <Route exact path="/maintenance" element={<Maintenance />} />
          <Route exact path="/comments" element={<Comments />} />
          <Route exact path="/me" element={<Profile />} />

          <Route exact path="/logout" element={<Logout />} />
        </Routes>
      </Main>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;