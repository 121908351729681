import { styled } from "styled-components";
import { getThemes } from "../../../styles/Themes";


export const NENameInput = styled.input`
  width: 100%;
  font-size: 2rem;
  padding: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${getThemes().light};
  font-family: ${getThemes().currentFont};
  font-weight: 600;
  text-align: center;
`;