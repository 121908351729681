import { createGlobalStyle } from "styled-components";
import { getThemes } from "../Themes";




const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
  }

  button, input, textarea, select{
    transition: border 0.15s, background 0.15s, color 0.15s, box-shadow 0.15s, opacity 0.15s, filter 0.15s, -webkit-filter 0.15s, backdrop-filter 0.15s, -webkit-backdrop-filter 0.15s, width 0.15s, height 0.15s, padding 0.15s, margin 0.15s;
  }

  input[type="number"]::-webkit-inner-spin-button, 
  input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .noselect {
    pointer-events: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */

    *{
      pointer-events: none;
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */
    }
  }
  .nohighlight{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */

    *{
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */
    }
  }



















  // naikho grid cols -------------------------------------------------
  .nk-row,
  .nk-row-col{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    &.column{
      flex-direction: column;
    }

    &.fill{
      height: 100%;
      width: 100%;
    }

    &.center{
      justify-content: center;
      align-items: center;
    }
    &.end{
      justify-content: flex-end;
    }
    &.wrap{
      flex-wrap: wrap;
    }
    
    &.stretch{
      align-items: stretch;
    }
    &.between{
      justify-content: space-between;
    }
  }
  .nk-row-col{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .nk-col-1{
    width: calc(100% / 12);
  }
  .nk-col-2{
    width: calc(100% / 6);
  }
  .nk-col-3{
    width: calc(100% / 4);
  }
  .nk-col-4{
    width: calc(100% / 3);
  }
  .nk-col-5{
    width: calc((100% / 12) * 5);
  }
  .nk-col-6{
    width: calc(100% / 2);
  }
  .nk-col-7{
    width: calc((100% / 12) * 7);
  }
  .nk-col-8{
    width: calc((100% / 12) * 8);
  }
  .nk-col-9{
    width: calc((100% / 12) * 9);
  }
  .nk-col-10{
    width: calc((100% / 12) * 10);
  }
  .nk-col-11{
    width: calc((100% / 12) * 11);
  }
  .nk-col-12{
    width: 100%;
  }

  .nk-col-1,
  .nk-col-2,
  .nk-col-3,
  .nk-col-4,
  .nk-col-5,
  .nk-col-6,
  .nk-col-7,
  .nk-col-8,
  .nk-col-9,
  .nk-col-10,
  .nk-col-11,
  .nk-col-12{
    padding: 0 5px;
    position: relative;

    &.label{
      padding-top: 1.5rem;
    }

    &.restrict{
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .nomarg{
    margin: 0 !important;
  }
  .nopad{
    padding: 0 !important;
  }

  .mt{
    margin-top: 25px !important;

    &.lg{
      margin-top: 50px !important;
    }
  }
  .mb{
    margin-bottom: 25px !important;
  }
  .mr{
    margin-right: 25px !important;
  }
  .ml{
    margin-left: 25px !important;
  }
  .mt-s{
    margin-top: 10px !important;
  }
  .mb-s{
    margin-bottom: 10px !important;
  }
  .mr-s{
    margin-right: 10px !important;
  }
  .ml-s{
    margin-left: 10px !important;
  }

  .mt-xl{
    margin-top: 30px !important;
  }
  .mb-xl{
    margin-bottom: 30px !important;
  }
  .mr-xl{
    margin-right: 30px !important;
  }
  .ml-xl{
    margin-left: 30px !important;
  }

  .bs{
    box-shadow: 0 5px 20px rgba(0,0,0,0.75);
  }
  .ts{
    text-shadow: 0 2px 10px rgba(0,0,0,1);
  }

  a{
    transition: 0.15s;
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }



  html{
    width: 100%;
    height: -webkit-fill-available;
    height: 100dvh !important;
    background-color: ${getThemes().dark};
    font-size: 100%;

    // remove scroll bar on safari
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    // remove scroll bar on all browsers
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1440px) {
      font-size: 90%;
    }

    body{
      height: 100%;
      width: 100%;
      overflow: visible !important;

      #root{
        width: 100%;
        height: 100%;
        overflow: visible !important;
      }
    }
  }
`;



export default GlobalStyle;