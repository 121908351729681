import React, { useContext } from 'react';
import { getThemes } from '../styles/Themes';
import { styled } from 'styled-components';
import { AppContext } from '../inc/AppContext';

const GhostLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.$zindex ?? 100};
`;

const VisibleGhostLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:${getThemes().darkGlass};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 110;
`;

const Ghost = (props) => {
  const app = useContext(AppContext);

  return (
    <GhostLayer
      $zindex={props.zindex}
      onClick={() => {
        app.setGhostContent(null);
        if(props.onClick) props.onClick();
      }}
    />
  );
};

const VisibleGhost = (props) => {
  const app = useContext(AppContext);

  return (
    <VisibleGhostLayer
      onClick={() => {
        app.setGhostFilledContent(null);
        if(props.onClick) props.onClick();
      }}
    />
  );
};

export {
  Ghost,
  VisibleGhost,
};