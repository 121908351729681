import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { eraseCookie } from '../inc/function.inc';
import { AppContext } from '../inc/AppContext';

const Logout = () => {
  const app = useContext(AppContext);
  const nav = useNavigate();

  useEffect(() => {
    app.setUser(null);
    app.setAccess(false);
    app.setGhostContent(null);
    app.setGhostFilledContent(null);
    app.setPopupCanvas(null);
    eraseCookie("token");

    nav("/");
  }, []);

  return (null);
};

export default Logout;