import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Copy, Eyes, NkBtn } from "./Buttons";
import { useContext } from "react";
import { AppContext } from "../inc/AppContext";
import { NkAreaStyle, NkCheckBoxPill, NkCheckBoxRow, NkCheckBoxSwitch, NkFieldBox, NkInputStyle, NkInputSuffix, NkRadioBtn, NkRadioContainer, NkRadioHover, NkRadioHoverContainer, NkSelectArrow, NkSelectStyle, NkSquareDropLayer, NkSquareImg, NkSquareImportBtn, NkSquareMediaBox, SearchBarStyle } from "../styles/Forms.style";
import ImgIcon from "../assets/images/image-icon.png";
import FileImg from "../assets/icons/document.svg";



const NkImageField = (props) => {
  const app = useContext(AppContext);

  const [darg, setDrag] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [src, setSrc] = React.useState(props.src);

  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);

  const inputRef = React.useRef(null);

  const importImage = async (files) => {
    if(files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        setFileName(file.name);

        // verify image size (props.import.MB_Size)
        if(props.import && props.import.MB_Size && file.size > props.import.MB_Size*1024*1024) {
          app.makeAlert(`Veuillez importer ${props.type === "file" ? "un fichier" : "une image"} de moins de ${String(props.import.MB_Size)}Mb`, "danger");
          inputRef.current.value = "";
          return;
        }

        // verify type (props.import.type: array)
        if(props.import && props.import.type && !props.import.type.includes(file.type)) {
          app.makeAlert(`Veuillez importer ${props.type === "file" ? "un fichier" : "une image"} de type: ${props.import.type.join(", ")}`, "danger");
          inputRef.current.value = "";
          return;
        }

        if(props.onChange) await props.onChange(file);
        // if image set src
        if(file.type && file.type.includes("image")) setSrc(e.target.result);
        // clear input
        inputRef.current.value = "";
        if(!props.skipSuccess) app.makeAlert(`${props.type === "file" ? "Fichier" : "Image"} importée avec succès`, "success");
      };
      reader.readAsDataURL(file);
    }
  };

  return(
    <NkSquareMediaBox
      $mb={props.mb}
      $width={props.import?.resolution?.width ?? props.width}
      $height={props.import?.resolution?.height ?? props.height}
      $flexMode={props.flexMode}
      aria-disabled={props.disabled}
      $drag={darg}
      onDragOver={(e)=>{
        if(!props.import || props.disabled) return;
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragEnter={(e)=>{
        if(!props.import || props.disabled) return;
        setDrag(true);
      }}
      onDragLeave={(e)=>{
        if(!props.import || props.disabled) return;
        setDrag(false);
      }}
      onDrop={async (e)=>{
        if(!props.import || props.disabled) return;
        e.preventDefault();
        e.stopPropagation();

        setDrag(false);
        await importImage(e.dataTransfer.files);
      }}
    >
      <input
        type="file"
        ref={inputRef}
        style={{display: "none"}}
        onChange={async (e)=>{
          await importImage(e.target.files);
        }}
      />
      <NkSquareImg
        src={src || props.default}
        $empty={!src}
      />
      {/* wrote name of file */}
      {
        props.type === "file" && fileName ?
          <p className="filename noselect">{fileName}</p>
        :
          null
      }
      {
        (props.import && !props.disabled) || props.overOnClick ?
          <>
            <NkSquareImportBtn
              onClick={()=>{
                if(!props.overOnClick) inputRef.current.click();
                else props.overOnClick();
              }}
            >
              <img src={props.type === "file" ? FileImg : ImgIcon} alt="" />
            </NkSquareImportBtn>
            <NkSquareDropLayer $dragging={darg}>Drop file</NkSquareDropLayer>
          </>
        :
          null
      }
    </NkSquareMediaBox>
  );
};

/**
 * @param {number} mb margin bottom (number)
 * @param {boolean} labelMono if true, label will be monospace
 * @param {string} label label of input
 * @param {string} placeholder placeholder of input
 * @param {any} value value of input
 * ---
 * @param {function} onKeyDown function to execute when key is pressed
 * @param {function} onBlur function to execute when input is blurred
 * @param {function} onClick function to execute when input is clicked
 * @param {function} onFocus function to execute when input is focused
 * @param {function} onMouseLeave function to execute when mouse leave input
 * @param {function} onMouseEnter function to execute when mouse enter input
 * @param {function} onChange function to execute when input value change
 */
const NkInput = (props) => {

  const app = useContext(AppContext);

  const [type, setType] = React.useState(props.type ?? "text");

  const inputRef = React.useRef(null);

  useEffect(() => {
    if(props.autoFocus && inputRef.current) inputRef.current.focus();
  }, [inputRef.current]);

  return(
    <NkFieldBox
      $mb={props.mb}
      $labelMono={props.labelMono}
    >
      {
        props.label ?
          <label className="noselect">{props.label}</label>
        :
          null
      }
      <div className={"nk-input-cage " + (props.suffix ? "suffix": "")}>
        <NkInputStyle
          $theme={app.theme.color}
          $size={props.size}
          $suffix={props.suffix}
          $mono={props.mono}
          type={type}
          value={props.value ?? ""}
          placeholder={props.placeholder}
          ref={inputRef}
          onChange={props.onChange}
          onKeyDown={(e)=>{
            if(e.key === "Enter"){
              if(props.onEnter) props.onEnter();
              inputRef.current.blur();
            }
          }}
          onBlur={props.onBlur}
          onClick={props.onClick}
          onFocus={props.onFocus}
          onMouseLeave={props.onMouseLeave}
          onMouseEnter={props.onMouseEnter}
          disabled={props.disabled}
        />
        {
          props.suffix ?
            <NkInputSuffix
              onClick={()=>{inputRef.current.focus();}}
            >{props.suffix}</NkInputSuffix>
          : null
        }
        <div className="nk-plugs-box">
          {
            props.type === "password" ?
              <Eyes
                show={type === "password"}
                onClick={()=>{
                  setType(type === "password" ? "text" : "password");
                }}
              />
            :
              null
          }
          {
            props.copy ?
              <Copy
                ml={props.type === "password"}
                onClick={()=>{
                  navigator.clipboard.writeText(props.value);
                }}
              />
            :
              null
          }
        </div>
      </div>
    </NkFieldBox>
  );
};

const NkArea = (props) => {

  const app = useContext(AppContext);

  const [type, setType] = React.useState(props.type ?? "text");

  return(
    <NkFieldBox
      $mb={props.mb}
      $area
    >
      {
        props.label ?
          <label className="noselect">{props.label}</label>
        :
          null
      }
      <div className="nk-input-cage">
        <NkAreaStyle
          $theme={app.theme.color}
          type={type}
          value={props.value ?? ""}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onKeyDown={(e)=>{
            if(e.key === "Enter" && props.onEnter) {
              props.onEnter();
            }
          }}
        />
        <div className="nk-plugs-box">
          {
            props.copy ?
              <Copy
                onClick={()=>{
                  navigator.clipboard.writeText(props.value);
                }}
              />
            :
              null
          }
        </div>
      </div>
    </NkFieldBox>
  );
};

const NkSelect = (props) => {
  const app = useContext(AppContext);

  const [open, setOpen] = React.useState(false);

  return(
    <NkFieldBox
      $mb={props.mb}
      $area
    >
      {
        props.label ?
          <label className="noselect">{props.label}</label>
        :
          null
      }
      <div className="nk-input-cage">
        <NkSelectStyle
          $theme={app.theme.color}
          $mono={props.mono}
          $size={props.size}
          $width={props.width}
          value={props.selected}
          onChange={(e)=>{
            if(props.onChange) props.onChange(parseInt(e.target.value));
          }}
          onBlur={() => setOpen(false)}
          onClick={() => setOpen(!open)}
          disabled={props.disabled}
        >
          {
            props.list?.map((item, index) => {
              return(
                <option key={index} value={index}>{item}</option>
              );
            })
          }
        </NkSelectStyle>
        {
          !props.disabled ? 
            <NkSelectArrow $open={open}>
              <FontAwesomeIcon icon={solid("chevron-down")} />
            </NkSelectArrow>
          : null
        }
      </div>
    </NkFieldBox>
  );
};

const NkCheckbox = (props) => {
  const app = useContext(AppContext);

  return(
    <NkFieldBox
      $mb={props.mb}
    >
      <NkCheckBoxRow
        $checked={props.checked}
        $opaque={props.opaque}
        onClick={() => {
          if(props.onChange) props.onChange(!props.checked);
        }}
        aria-disabled={props.disabled}
      >
        <p>{props.label}</p>
        <NkCheckBoxPill
          $theme={app.theme.color}
          $checked={props.checked}
        >
          <span className="nk-icon">
            <FontAwesomeIcon icon={solid("check")} />
          </span>
        </NkCheckBoxPill>
      </NkCheckBoxRow>
    </NkFieldBox>
  );
};

const NkRadio = (props) => {
  const app = useContext(AppContext);

  return(
    <NkFieldBox
      $mb={props.mb}
    >
      {
        props.label ?
          <label className="noselect">{props.label}</label>
        :
          null
      }
      <div className="nk-input-cage">
        <NkRadioContainer
          aria-disabled={props.disabled}
        >
          <NkRadioHoverContainer>
            <NkRadioHover
              $theme={app.theme.color}
              $selected={props.selected}
              $length={props.list?.length}
            />
          </NkRadioHoverContainer>
          {
            props.list?.map((item, index) => {
              return(
                <NkRadioBtn
                  key={index}
                  $theme={app.theme.color}
                  $length={props.list?.length}
                  $selected={props.selected === index}
                  onClick={() => {
                    if(props.onChange) props.onChange(index);
                  }}
                >
                  <span className="noselect">{item}</span>
                </NkRadioBtn>
              );
            })
          }
        </NkRadioContainer>
      </div>
    </NkFieldBox>
  );
};

const NkSwitch = (props) => {
  const app = useContext(AppContext);

  return(
    <NkFieldBox
      $mb={props.mb}
    >
      <NkCheckBoxRow
        $checked={props.checked}
        onClick={() => {
          if(props.onChange) props.onChange(!props.checked);
        }}
        aria-disabled={props.disabled}
      >
        <p>{props.label}</p>
        <NkCheckBoxSwitch
          $theme={app.theme.color}
          $checked={props.checked}
        >
          <span className="nk-pill" />
        </NkCheckBoxSwitch>
      </NkCheckBoxRow>
    </NkFieldBox>
  );
};


/**
 * @bool waitValidate : if true, validate button will wait for onValidate function to be executed
 * @func onValidate : function to execute when validate button is clicked
 * @func onCancel : function to execute when cancel button is clicked
 * @object {valid} : object to customize validate button
 * @object {cancel} : object to customize cancel button
 * @objectsOptions [name, type, less, superLess]
 */
const NkDuoBtns = (props) => {
  const app = useContext(AppContext);

  return(
    <div className="nk-row">
      <div className="nk-col-6">
        <NkBtn
          name={props.valid?.name ?? "Valiate"}
          fill={true}
          less={props.valid?.less ?? false}
          superLess={props.valid?.superLess ?? false}
          type={props.valid?.type ?? "primary"}
          onClick={async () => {
            if(props.waitValidate && props.onValidate){
              if(await props.onValidate()) app.resetCanvasPopup();
            } else {
              if(props.onValidate) props.onValidate();
              app.resetCanvasPopup();
            }
          }}
        />
      </div>
      <div className="nk-col-6">
        <NkBtn
          name={props.cancel?.name ?? "Cancel"}
          type={props.cancel?.type ?? null}
          fill={true}
          less={props.cancel?.less ?? false}
          superLess={props.cancel?.superLess ?? true}
          onClick={() => {
            if(props.onCancel) props.onCancel();
            app.resetCanvasPopup();
          }}
        />
      </div>
    </div>
  );
};

const NkExitBtn = (props) => {
  /**
   * @func [onClick] : function to execute when button is clicked
   * @objectsOptions [name]
   */

  const app = useContext(AppContext);

  return(
    <NkBtn
      name={props.name ?? "Close"}
      less={true}
      onClick={() => {
        if(props.onClick) props.onClick();
        app.resetCanvasPopup();
      }}
    />
  );
};

const NkSearchBar = (props) => {
  const app = useContext(AppContext);
  return(
    <SearchBarStyle
      $theme={app.theme.color}
      $mr={props.mr}
      type="text"
      placeholder={props.placeholder ?? "Search"}
      onChange={(e) => {
        let value = e.target.value.split(",").join(" ").split(";").join(" ").split(".").join(" ").split(" ");
        // if the last === "" then remove it
        if(value[value.length - 1] === "") value.pop();
        if(props.onChange) props.onChange(value);
      }}
    />
  );
};


export {
  NkImageField,
  NkInput,
  NkArea,
  NkSelect,
  NkCheckbox,
  NkRadio,
  NkSwitch,
  NkDuoBtns,
  NkExitBtn,
  NkSearchBar
};