import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import { NkContainer, PageTitle } from '../styles/Styles';
import { RBCheckbox, RBImg, RBInfos, RBInfosText, RBName, RBStatus, RestauContainer, RestaurantBox, SearchBarContainer, ToolBtn, ToolBtnIcon, ToolBtnText, ToolsContainer } from '../styles/environment/Environment.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NkRadio, NkCheckbox, NkSearchBar } from '../components/Forms';
import { AppContext } from '../inc/AppContext';
import AddEnvForm from './environments/NewEnv';
import { useState } from 'react';
import { NkVSeparator } from '../styles/Forms.style';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { nkFetch } from '../inc/function.inc';



const EnvToolsBtn = (props) => {
  return(
    <ToolBtn onClick={props.onClick} disabled={props.disabled}>
      <ToolBtnIcon>
        <FontAwesomeIcon icon={props.icon} />
      </ToolBtnIcon>
      <ToolBtnText>{props.text}</ToolBtnText>
    </ToolBtn>
  );
}

const RestaurantCard = (props) => {
  const status = {
    open: "Ouvert",
    close: "Fermé"
  };

  return(
    <RestaurantBox onClick={props.onClick}>
      {
        props.selectMode ?
          <RBCheckbox>
            <NkCheckbox
              checked={props.selected}
              opaque={true}
            />
          </RBCheckbox>
        : null
      }
      <RBImg className="noselect" src={props.image} alt="" />
      <RBInfos className="noselect" $selected={props.selected}>
        <RBInfosText>{props.text}</RBInfosText>
        <RBName>{props.name}</RBName>
        <RBStatus $status={props.status}>{status[props.status]}</RBStatus>
      </RBInfos>
    </RestaurantBox>
  );
};

const Environment = () => {
  const app = useContext(AppContext);
  const nav = useNavigate();

  const [status, setStatus] = useState(0);
  const [scroll, setScroll] = useState(window.scrollY > 0);

  const [selectMode, setSelectMode] = useState(false);
  const [selecteds, setSelecteds] = useState([]);

  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState([]);

  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    // listen for html scroll:
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });

    // generate random data for 50 restaurants with name, status (open/close) and image:
    const tmp = [];
    for (let i = 0; i < 50; i++) {
      let type = ["active", "active", "active", "active", "banned", "archived", "archived"][Math.floor(Math.random() * 7)];
      tmp.push({
        // generate long string between 10 and 40 chars:
        name: Math.random().toString(36).substring(2, 15 + Math.random() * 25),
        status: Math.random() > 0.5 ? "open" : "close",
        image: "https://picsum.photos/seed/" + i + "/300/200",
        type: type,
        text: (type === "active" ? "500+ Employés" : (type === "banned" ? "Raison: Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi reprehenderit, explicabo laborum earum, deserunt mollitia voluptatum molestias error corporis sed quasi, numquam ab saepe nesciunt necessitatibus. Quam harum magni adipisci aut, inventore modi? Iusto rerum quis vel atque soluta obcaecati eos non aliquid dignissimos, consectetur, distinctio, fugit molestias ipsa doloremque!" : "Archivé le 12/12/2020"))
      });
    }

    setRestaurants(tmp);
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <NkContainer>
        <PageTitle>Outils</PageTitle>

        <ToolsContainer $scroll={scroll} $searching={searching}>
          <EnvToolsBtn
            text="Nouveau"
            icon={solid("plus")}
            onClick={() => nav("/environments/create")}
          />
          <EnvToolsBtn
            text="Chercher"
            icon={solid("search")}
            onClick={() => {
              setSearching(!searching);
            }}
          />
          <EnvToolsBtn
            text={selectMode ? "Annuler" : "Selectionner"}
            icon={selectMode ? solid("times") : solid("mouse-pointer")}
            onClick={() => {
              setSelectMode(!selectMode);
              setSelecteds([]);
            }}
          />
          {
            selectMode ?
              <Fragment>
                <NkVSeparator $noh />
                <EnvToolsBtn
                  text="Bannir"
                  icon={solid("ban")}
                  onClick={() => {}}
                  disabled={selecteds.length === 0}
                />
                <EnvToolsBtn
                  text="Archiver"
                  icon={solid("archive")}
                  onClick={() => {}}
                  disabled={selecteds.length === 0}
                />
              </Fragment>
            : null
          }

          <SearchBarContainer $show={searching}>
            <NkSearchBar
              placeholder="Rechercher..."
              onChange={setSearch}
            />
          </SearchBarContainer>
        </ToolsContainer>



        <PageTitle>Restaurants</PageTitle>
        <NkRadio
          mb="25"
          selected={status}
          list={[
            "Actifs",
            "Bannis",
            "Archivés"
          ]}
          onChange={setStatus}
        />

        <RestauContainer>
          {
            restaurants.map((restau, index) => {
              if(status === 0 && restau.type !== "active") return null;
              if(status === 1 && restau.type !== "banned") return null;
              if(status === 2 && restau.type !== "archived") return null;

              if(searching && search.length !== 0 && !search.some(elm => restau.name.includes(elm))) return null;

              return(
                <RestaurantCard
                  key={index}
                  image={restau.image}
                  name={restau.name}
                  status={restau.status}
                  selectMode={selectMode}
                  selected={selecteds.includes(index)}
                  text={restau.text}
                  onClick={async ()=>{
                    if(selectMode){
                      if(selecteds.includes(index)){
                        setSelecteds(selecteds.filter(i => i !== index));
                      } else {
                        setSelecteds([...selecteds, index]);
                      }
                    } else {
                      const r = await nkFetch("/own/restaurant");

                      if(r.status === 200){
                        console.log(r.data);
                      }
                    }
                  }}
                />
              );
            })
          }
        </RestauContainer>

      </NkContainer>
    </motion.div>
  );
};

export default Environment;