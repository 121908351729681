import React, { useContext } from 'react';
import { AppContext } from './AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeaderName, HeaderSection, HeaderSectionNav, HeaderSectionTitle, HeaderWrapper } from '../styles/header/Header.style';
import { HeaderBtn } from '../components/Buttons';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';



const Header = () => {
  const app = useContext(AppContext);
  const nav = useNavigate();
  const loc = useLocation();

  return (
    <HeaderWrapper>
      <HeaderName className='noselect'>Qr Eat</HeaderName>

      <HeaderSection>
        <HeaderSectionTitle className='noselect'>DASHBOARD</HeaderSectionTitle>
        <HeaderSectionNav>
          <HeaderBtn
            to={"/dashboard"}
            name={"Dashboard"}
            icon={solid("home")}
          />
          <HeaderBtn
            to={"/environments"}
            name={"Evironnements"}
            icon={solid("box")}
          />
        </HeaderSectionNav>
      </HeaderSection>

      <HeaderSection>
        <HeaderSectionTitle className='noselect'>GESTION</HeaderSectionTitle>
        <HeaderSectionNav>
          <HeaderBtn
            to={"/users"}
            name={"Super admins"}
            icon={solid("user-shield")}
          />
          <HeaderBtn
            to={"/helps"}
            name={"Assistance"}
            icon={solid("headset")}
            disabled={true}
          />
          <HeaderBtn
            to={"/integrations"}
            name={"Intégrations"}
            icon={solid("gears")}
            disabled={true}
          />
          <HeaderBtn
            to={"/maintenance"}
            name={"Maintenance"}
            icon={solid("toolbox")}
            disabled={true}
          />
          <HeaderBtn
            to={"/comments"}
            name={"Avis & Sondages"}
            icon={solid("chart-pie")}
            disabled={true}
          />
        </HeaderSectionNav>
      </HeaderSection>

      <HeaderSection>
        <HeaderSectionTitle className='noselect'>COMPTE</HeaderSectionTitle>
        <HeaderSectionNav>
          <HeaderBtn
            to={"/me"}
            name={"Mon compte"}
            icon={solid("user")}
          />
          <HeaderBtn
            to={"/logout"}
            name={"Déconnexion"}
            icon={solid("arrow-right-from-bracket")}
          />
        </HeaderSectionNav>
      </HeaderSection>

    </HeaderWrapper>
  );
};

export default Header;