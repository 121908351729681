import { styled } from "styled-components";
import { getThemes } from "../../styles/Themes";
import { SearchBarStyle } from "../Forms.style";



export const ToolsContainer = styled.div`
  transition: 0.25s;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  margin-bottom: 50px;
  position: sticky;
  top: 25px;
  z-index: 50;
  border-radius: 25px;

  >*{
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${SearchBarStyle} {
    width: 100%;
    font-size: 1.2rem;
    padding: 15px 20px;
    border-radius: 20px;
    background-color: ${getThemes().layerDark};
  }

  ${props => props.$scroll ? `
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    background-color: ${getThemes().softDarkGlass};
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: fit-content;
    margin: 0 auto;
    box-shadow: 0 10px 25px -10px ${getThemes().darkGlass};

    >*{
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    ${SearchBarStyle} {
      width: 100%;
      font-size: 1.5rem;
      padding: 20px;
      border-radius: 25px;
      background-color: ${getThemes().layerDark};
      box-shadow: 0 10px 25px -10px ${getThemes().darkGlass};
    }

    ${SearchBarContainer} {
      width: 100%;
      top: 110%;
      left: 50%;
      transform: translateX(-50%);
    }

    ${ToolBtn} {
      width: 100px;
      padding: 10px 0;
      border-radius: 10px;

      ${ToolBtnText}{
        display: none;
      }

      ${ToolBtnIcon}{
        font-size: 1.5rem;
        margin-bottom: 0;
      }

      &:hover {
        border-radius: 15px;
      }
    }
  ` : ``}

  ${props => props.$searching ? `
    min-width: 500px;
  ` : ``}
`;



export const ToolBtn = styled.button`
  transition: 0.25s;
  background-color: transparent;
  width: 150px;
  padding: 30px 0;
  border-radius: 15px;
  opacity: 0.5;
  color: ${getThemes().light};

  &:hover {
    opacity: 1;
    background-color: ${getThemes().primaryColorOver};
    color: ${getThemes().primaryColor};
    border-radius: 25px;
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }
`;

export const ToolBtnIcon = styled.p`
  font-size: 2.1rem;
  margin-bottom: 15px;
`;

export const ToolBtnText = styled.p`
  font-size: 1rem;
  font-family: ${getThemes().currentFont};
`;



export const SearchBarContainer = styled.div`
  width: 300px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s;

  ${props => props.$show ? `
    opacity: 1;
    pointer-events: all;
  ` : ``}
`;



export const RestauContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
`;



export const RBCheckbox = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  pointer-events: none;
`;

export const RBImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const RBInfos = styled.div`
  transition: 0.25s;
  transition-delay: 0.2s;
  position: relative;
  background-color: ${props => props.$selected ? getThemes(props.$theme).primaryColorAlpha : getThemes().layerDarkAlpha};
  width: 100%;
  height: 50%;
  padding: 15px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  overflow: hidden;
`;

export const RBInfosText = styled.p`
  transition: 0.25s;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  padding-bottom: 0;
  width: 100%;

  font-size: 0.8rem;
  font-family: ${getThemes().currentFont};
  color: ${getThemes().light};
  line-height: 1rem;
  pointer-events: none;
  opacity: 0;

  z-index: 2;
  /* ellipse on 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const RBName = styled.p`
  position: relative;
  z-index: 2;
  color: ${getThemes().light};
  font-family: ${getThemes().currentFont};
  font-size: 1rem;
  margin-bottom: 8px;
  width: 100%;
  line-height: 1.1rem;
  /* ellipse on 2 line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const RBStatus = styled.p`
  position: relative;
  z-index: 2;
  color: ${getThemes().lightAlpha};
  font-family: ${getThemes().currentFont};
  font-size: 0.9rem;
  width: 100%;

  &:after{
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: ${props => props.$status === "open" ? getThemes().checkColor : getThemes().dangerColor};
    box-shadow: 0 0 15px ${props => props.$status === "open" ? getThemes().checkColorAlpha : getThemes().dangerColorAlpha};
    z-index: 1;
    border-radius: 50%;
  }
`;

export const RestaurantBox = styled.div`
  transition: 0.35s;
  width: calc(20% - 12px);
  margin-right: 15px;
  margin-bottom: 15px;
  /* flex-grow: 1; */
  height: 150px;
  border-radius: 15px;
  background-color: ${getThemes().lightOver};
  overflow: hidden;
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-shadow: 0 0 0 ${getThemes().dark};

  &:nth-child(5n) {
    margin-right: 0;
  }

  &:hover {
    /* transition-delay: 0.2s; */
    transform: scale(1.15);
    z-index: 1;
    box-shadow: 0 5px 25px ${getThemes().dark};

    ${RBInfos} {
      height: 100%;
    }

    ${RBInfosText} {
      opacity: 1;
      transition-delay: 0.35s;
    }
  }

  &:active {
    transform: scale(1.1);
    transition-delay: 0s;
  }
`;
