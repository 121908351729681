import React, { useContext } from 'react';
import { NkContainer } from '../styles/Styles';
import { LoginBox, LoginTitle } from '../styles/login/Login.style';
import { NkInput } from '../components/Forms';
import { NkBtn } from '../components/Buttons';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppContext } from '../inc/AppContext';
import { eraseCookie, nkFetch, setCookie } from '../inc/function.inc';

const Login = () => {
  const app = useContext(AppContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [btn, setBtn] = React.useState("Connexion");

  React.useEffect(() => {
    document.title = "Login | Qr Eat Admin";
  }, []);
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{height: "100%"}}
    >
      <NkContainer $flex $center $column>
        <LoginTitle>Login</LoginTitle>
        <LoginBox>
          <div className="nk-col-12 mb">
            <NkInput
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="nk-col-12 mb">
            <NkInput
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="nk-col-12">
            <NkBtn
              name={btn}
              fill={true}
              less={true}
              onClick={() => {
                setBtn(<FontAwesomeIcon icon={solid("circle-notch")} spin />);
                if(email === "" || password === ""){
                  app.makeAlert("Veuillez remplir tous les champs", "danger");
                  setBtn("Se connecter");
                  return;
                }
                nkFetch(
                  "/login",
                  "post",
                  {
                    email: email,
                    password: password,
                    loginType: "owner",
                  },
                  true
                )
                .then((r) => {
                  if(r.status === 200){
                    app.setAccess(true);
                    app.setUser(r.message?.data);
                    setCookie("token", r.message?.token, 1);
                  } else {
                    app.makeAlert("Error: " + r.message, "danger");
                    eraseCookie("token");
                    setBtn("Se connecter");
                  }
                });
              }}
            />
          </div>
        </LoginBox>
      </NkContainer>
    </motion.div>
  );
};

export default Login;