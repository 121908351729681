import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedRoutes from './inc/AnimatedRoutes';
import { AppContext } from './inc/AppContext';
import Header from './inc/Header';
import { styled } from 'styled-components';
import AlertsCanvas from './components/Alerts';
import ResetStyle from './styles/global/Reset.style';
import GlobalStyle from './styles/global/Global.style';
import { FontDmSansImport, FontMonserratImport, FontSfUiDisplayImport } from './styles/global/Fonts.import';
import { NkSplitContainer } from './styles/Styles';
import Login from './pages/Login';
import Loading from './components/Loading';
import { eraseCookie, nkFetch } from './inc/function.inc';
import { useQuery } from '@tanstack/react-query';

const EnvWrapper = styled.div`
  height: 100%;
  width: 100%;
`;



function App() {
  const app = React.useContext(AppContext);

  const {
    isLoading,
    data
  } = useQuery({
    queryKey: ["user", "me"],
    queryFn: () => nkFetch("/login/check", "get"),
    refetchOnWindowFocus: false,
    retry: false,
    meta: {
      notLogin: true
    }
  });

  React.useEffect(() => {
    if(data && data.status === 200){
      app.setAccess(true);
      app.setUser(data.message);
    } else if(!isLoading) {
      app.setAccess(false);
      app.setUser(null);
      if(data && data.status === 401) eraseCookie("token");
    }
  }, [data]);

  if(isLoading || app.access === null) return (<Loading />);

  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <EnvWrapper>
        <FontSfUiDisplayImport />
        <FontMonserratImport />
        <FontDmSansImport />

        <ResetStyle />
        <GlobalStyle />

        <AlertsCanvas />
        {app.contextMenu}
        {app.popupCanvas}
        {app.ghostFilledContent}
        {app.ghostContent}

        {
          app.access && app.user ?
            <NkSplitContainer>
              <Header />
              <AnimatedRoutes/>
            </NkSplitContainer>
          :
            <Login />
        }
      </EnvWrapper>
    </Router>
  );
}

export default App;
