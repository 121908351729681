import React, { useContext } from 'react';
import { CanvasPopupContainer, ChoicePopupCanvas } from '../styles/Popup.style';
import { NkBtn } from './Buttons';
import { NkFormSection, NkHSeparator } from '../styles/Forms.style';
import { AppContext } from '../inc/AppContext';

/**
 * @var title
 * @var description: string
 * @var alert: string
 * @var lbtn | @var rbtn:
 *      @var label: string
 *      @var type: string
 *      @var less: boolean
 *      @var fill: boolean
 *      @var fullFill: boolean
 *      @var size: string
 *      @func onClick: function
 *      @func onMouseEnter: function
 *      @func onMouseLeave: function
 */
const ChoicePopup = (props) => {

  const app = useContext(AppContext);

  return (
    <ChoicePopupCanvas>
      <NkFormSection>
        <div className="nk-col-12 noselect">
          <h2>{props.title}</h2>
        </div>
      </NkFormSection>
      <NkHSeparator />
      <NkFormSection>
        <div className="nk-col-12 noselect">
          {
            props.description && props.description.length > 0 ?
              <p>
                {props.description}
              </p>
            :
              null
          }
          {
            props.alert && props.alert.length > 0 ?
              <small>
                {props.alert}
              </small>
            :
              null
          }
        </div>
      </NkFormSection>
      <NkHSeparator />
      <NkFormSection>
        <div className="nk-row">
          <div className={"nk-row center " + (props.rbtn ? "nk-col-6" : "nk-col-12")}>
            <NkBtn
              name={props.lbtn?.label}
              type={props.lbtn?.type}
              less={props.lbtn?.less}
              superLess={props.lbtn?.superLess}
              fill={props.lbtn?.fill}
              fullFill={props.lbtn?.fullFill}
              size={props.lbtn?.size}
              onClick={()=>{
                if(props.lbtn?.onClick) props.lbtn?.onClick();
                app.resetChoicePopup();
              }}
              onMouseEnter={props.lbtn?.onMouseEnter}
              onMouseLeave={props.lbtn?.onMouseLeave}
              className={props.lbtn?.className}
            />
          </div>
          {
            props.rbtn ?
              <div className="nk-col-6 nk-row center">
                <NkBtn
                  name={props.rbtn?.label}
                  type={props.rbtn?.type}
                  less={props.rbtn?.less}
                  superLess={props.rbtn?.superLess}
                  fill={props.rbtn?.fill}
                  fullFill={props.rbtn?.fullFill}
                  size={props.rbtn?.size}
                  onClick={()=>{
                    if(props.rbtn?.onClick) props.rbtn?.onClick();
                    app.resetChoicePopup();
                  }}
                  onMouseEnter={props.rbtn?.onMouseEnter}
                  onMouseLeave={props.rbtn?.onMouseLeave}
                  className={props.rbtn?.className}
                />
              </div>
            :
              null
          }
        </div>
      </NkFormSection>
    </ChoicePopupCanvas>
  );
};

const CanvasPopup = (props) => {
  return (
    <CanvasPopupContainer>
      <div className="nk-cp-title-box">
        <h2 className="noselect">
          {props.title}
          {
            props.subTitle ?
              <span> &nbsp;•&nbsp; {props.subTitle}</span>
            :
              null
          }
        </h2>
      </div>
      <div className="nk-cp-container">
        {props.component}
      </div>
    </CanvasPopupContainer>
  );
};

export {
  ChoicePopup,
  CanvasPopup,
};