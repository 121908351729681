import styled from "styled-components";
import { getThemes } from "../Themes";



export const LoginBox = styled.div`
  width: 600px;
  max-width: 90%;
`;

export const LoginTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: ${getThemes().light};
  font-family: ${getThemes().titleFont};
  margin-bottom: 1.5rem;
`;