import { NkDarkStyles } from "./NkStyles";

const Themes = {
  classic: {
    primaryColor: `rgb(177, 109, 255);`,
    primaryColorAlpha: `rgba(177, 109, 255, 0.4);`,
    primaryColorGlass: `rgba(177, 109, 255, 0.8);`,
    primaryColorOver: `rgba(177, 109, 255, 0.1);`,
    primaryColorTrans: `rgba(177, 109, 255, 0);`,

    secondaryColor: `rgb(169, 145, 255);`,
    secondaryColorAlpha: `rgba(169, 145, 255, 0.4);`,
    secondaryColorGlass: `rgba(169, 145, 255, 0.8);`,
    secondaryColorOver: `rgba(169, 145, 255, 0.1);`,
    secondaryColorTrans: `rgba(169, 145, 255, 0);`,
  },
};

const getThemes = (theme=null) => {
  if(theme === "all") return Themes;
  if(theme && theme.length > 0 && Themes[theme]) {
    return {
      ...Themes[theme],
      ...NkDarkStyles
    };
  }
  return {
    ...Themes["classic"],
    ...NkDarkStyles
  };
};

export {
  getThemes
}