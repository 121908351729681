import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { styled } from 'styled-components';
import { getThemes } from '../styles/Themes';



const LoadingStyle = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-reverse {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .nk-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    span.l1{
      animation: spin 3s linear infinite;
      font-size: 4rem;
      width: 80px;
      height: 80px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${props => getThemes().light};
    }

    span.l2{
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      animation: spin-reverse 3s linear infinite;
      font-size: 2rem;
      width: 60px;
      height: 60px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      color: ${props => getThemes().light};
    }

    span.l3{
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${props => getThemes().light};
      opacity: 0.25;
    }
  }
`;

const Loading = () => {
  return (
    <LoadingStyle className="nk-row center fill">
      <div className="nk-loading">
        <span className="l1"><FontAwesomeIcon icon={solid('circle-notch')} /></span>
        <span className="l2"><FontAwesomeIcon icon={solid('circle-notch')} /></span>
        <span className="l3"></span>
      </div>
    </LoadingStyle>
  );
};

export default Loading;