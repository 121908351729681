import { styled } from "styled-components";
import { getThemes } from "../../styles/Themes";



export const HeaderWrapper = styled.header`
  width: 260px;
  height: 100%;
  flex-shrink: 0;
  background-color: ${props => getThemes(props.$theme).softDark};
  position: fixed;
  top: 0;
  left: 0;
`;

export const HeaderName = styled.h1`
  font-family: ${getThemes().currentFont};
  font-size: 1.5rem;
  color: ${getThemes().light};
  width: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 60px;
  font-weight: 300;
  text-shadow: 0 0 15px ${getThemes().lightAlpha};
`;



export const HeaderSection = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const HeaderSectionTitle = styled.p`
  width: 100%;
  padding-left: 25px;
  font-size: 0.8rem;
  font-family: ${getThemes().currentFont};
  color: ${getThemes().light};
  opacity: 0.5;
  font-weight: 300;
  margin-bottom: 10px;
`;

export const HeaderSectionNav = styled.nav``;