import { v4 as uuidv4 } from "uuid";

export function setCookie(name ,value, days, secure=false, sameSite="Lax") {
  let cookie = name + "=" + (value || "") + "; path=/";
  if(secure) cookie += "; secure";
  if(sameSite) cookie += "; SameSite=" + sameSite;
  if(days){
    let date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    cookie += "; expires=" + date.toUTCString();
  }
  document.cookie = cookie;
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export function eraseCookie(name) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getToken(){
  return getCookie("token");
}

export function setLocalStorage(key, value) {
  if(typeof value === "object") value = JSON.stringify(value);
  else value = String(value);
  localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
  let res = localStorage.getItem(key);
  if(res === null) return null;
  try{
    return JSON.parse(res);
  } catch(e) {
    return res;
  }
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key);
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function isInLocalStorage(key) {
  return localStorage.getItem(key) !== null;
}

export async function requests(url, method, headers={}, data={}) {
  if(!(data instanceof FormData)) headers["Content-Type"] = "application/json";

  let options = {
    method: method.toUpperCase(),
    headers: headers,
  }
  if(options.method !== "GET"){
    options.body = (data instanceof FormData ? data : JSON.stringify(data));
  }

  try{
    const r = await fetch(
      String(url),
      options,
    );
    return r;
  }catch(e){
    return new Response(null, {status: 500});
  }
}



function NkError(data) {
  const error = new Error(data.message);
  error.data = data;
  return error;
};

export async function nkFetch(apiCategory, method="GET", data={}, skipToken=false){
  let token = getToken();
  if(!token && !skipToken) return null;

  if(method === "GET"){
    if(Object.keys(data).length > 0){
      let tmp = "";
      for(let k in data){
        tmp += k + "=" + data[k] + "&";
      }
      tmp = tmp.substring(0, tmp.length - 1);
      apiCategory += "?" + tmp;
    }
  }

  const r = await requests(
    process.env.REACT_APP_API + apiCategory,
    method,
    {
      "Authorization": "Bearer " + String(token)
    },
    data,
  );

  let response = null;
  try{
    response = await r.json();
  }catch(e){}

  if(r.ok && (r.status === 200 || r.status === 201)){
    return {
      ...response,
      status: r.status,
    };
  } else {
    throw NkError({
      ...response,
      message: response?.message || "Error",
      status: r.status
    });
  }
}



export function getChars(onlyLetters=false) {
  const lettersMin = "abcdefghijklmnopqrstuvwxyz";
  const lettersMaj = lettersMin.toUpperCase();
  const numbers = "0123456789";
  return lettersMin + lettersMaj + (!onlyLetters ? numbers : "");
}

export function uniqid(){
  return uuidv4();
}