import React, { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../inc/AppContext';
import { EllipseBtn, EllipseContainer, EllipseContextMenu, EllipseContextMenuItem, HeaderBtnStyle, MenuOptionCloseBtn, NkBtnStyle, NkDeployBtnStyle, NkIconBtnStyle, PlugInputBtn } from '../styles/Buttons.style';
import { Ghost } from './Ghost';
import { uniqid } from '../inc/function.inc';



// const HeaderBtn = (props) => {
//   const location = useLocation();

//   return (
//     <div className={(location.pathname.startsWith(props.to) ? "select" : "") + " " + props.className} $open={props.open}>
//       <Link
//         to={props.to}
//         className="nk-nav-btn"
//         onClick={(e)=>{
//           if(location.pathname !== props.to){
//             if(props.onClick) props.onClick();
//           }
//         }}
//         title={!props.open ? props.name : ""}
//       >
//       </Link>
//     </div>
//   );
// }

const HeaderBtn = (props) => {
  const location = useLocation();

  return (
    <HeaderBtnStyle
      $select={location.pathname.startsWith(props.to)}
      disabled={props.disabled}
    >
      <Link
        to={props.to}
        className="nk-nav-btn"
        onClick={(e)=>{
          if(location.pathname !== props.to){
            if(props.onClick) props.onClick();
          }
        }}
        title={!props.open ? props.name : ""}
      >
        {
          props.icon ?
            <span className="icon noselect">
              <FontAwesomeIcon icon={props.icon} />
            </span>
          :
            null
        }
        {
          props.name ?
            <span className="name noselect">
              {props.name}
            </span>
          :
            null
        }
      </Link>
    </HeaderBtnStyle>
  );
}

const BurgerBtn = (props) => {
  return (
    <button
      className={"nk-burger-btn " + (props.nkclass || "")}
      onClick={props.clickHandler}
    >
      {props.icon}
    </button>
  );
}

const Eyes = (props) => {
  return(
    <PlugInputBtn
      $ml={props.ml}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={(props.show)? solid('eye') : solid('eye-slash')} /> 
    </PlugInputBtn>
  );
};

const Copy = (props) => {
  return(
    <PlugInputBtn
      $ml={props.ml}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={solid('clone')} /> 
    </PlugInputBtn>
  );
};



const EllipseContextBtn = (props) => {
  const [btnName, setBtnName] = React.useState(props.name);
  const [clicked, setClicked] = React.useState(false);

  useEffect(() => {
    if(!clicked) setBtnName(props.name);
  }, [props.name]);

  return(
    <EllipseContextMenuItem
      $danger={props.danger}
      $top={props.top}
      $left={props.left}
      $zindex={props.zindex}
      $between={props.icon}
      onClick={()=>{
        if(props.danger && !clicked){
          setClicked(true);
          setBtnName("Sûr ?");
        }else{
          if(props.onClick) props.onClick();
          setClicked(false);
        }
      }}
      onMouseLeave={()=>{
        if(clicked){
          setBtnName(props.name);
          setClicked(false);
        }
      }}
    >
      <span>{btnName}</span> {props.icon ? <FontAwesomeIcon icon={props.icon} /> : null}
    </EllipseContextMenuItem>
  );
};

const MenuEllipseBtn = (props) => {

  const app = useContext(AppContext);

  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <EllipseContainer>
      <EllipseBtn
        onClick={()=>{
          if(props.onClick) props.onClick();
          if(props.context){
            app.setGhostContent(<Ghost
              onClick={()=>{
                setOpenMenu(false);
              }}
            />);
            setOpenMenu(true);
          }
        }}
      >
        <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
      </EllipseBtn>
      <EllipseContextMenu
        $open={openMenu && props.context}
      >
        {
          props.context ?
            props.context.map((item, index)=>{
              return(
                <EllipseContextBtn
                  key={uniqid()}
                  name={item.name}
                  danger={item.danger}
                  onClick={()=>{
                    if(item.onClick) item.onClick();
                    setOpenMenu(false);
                    app.setGhostContent(null);
                  }}
                />
              );
            })
          : null
        }
      </EllipseContextMenu>
    </EllipseContainer>
  );
};


const NkBtn = (props) => {

  const app = useContext(AppContext);

  return(
    <NkBtnStyle
      $theme={app.theme.color}
      $type={props.type}
      $less={props.less}
      $superLess={props.superLess}
      $mono={props.mono}
      $fill={props.fill}
      $fullFill={props.fullFill}
      $size={props.size}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={props.className}
      aria-disabled={props.disabled}
    >
      <span className="noselect">{props.name}</span>
    </NkBtnStyle>
  );
};



const NkIconBtn = (props) => {
  const app = useContext(AppContext);
  return(
    <NkIconBtnStyle
      className={props.className}
      $theme={app.theme.color}
      $type={props.type}
      $size={props.size}
      $nobg={props.nobg}
      $blur={props.blur}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={props.icon} spin={props.spin}/>
    </NkIconBtnStyle>
  );
};



const NkDeployBtn = (props) => {
  const app = useContext(AppContext);

  return(
    <NkDeployBtnStyle
      $deployWidth={props.deployWidth}
      $retractWidth={props.retractWidth}
      onClick={props.onClick}
    >
      <p className='noselect'>{props.name}</p>
      <span className='noselect'>
        <FontAwesomeIcon icon={solid("plus")} />
      </span>
    </NkDeployBtnStyle>
  );
};



export {
  HeaderBtn,
  BurgerBtn,
  Eyes,
  Copy,
  EllipseContextBtn,
  MenuEllipseBtn,
  NkBtn,
  NkIconBtn,
  NkDeployBtn
};