import { styled } from "styled-components";
import { getThemes } from "./Themes";

export const NkContainer = styled.div`
  width: ${props => props.$wless ? `calc(100% - ${props.$wless})` : `100%`};
  height: ${props => props.$hless ? `calc(100% - ${props.$hless})` : `100%`};
  padding: ${props => props.$nopad ? "0" : "25px"};

  ${props => props.$flex ? `display: flex;` : null}
  ${props => props.$center ? `justify-content: center; align-items: center;` : null}
  ${props => props.$jcenter ? `justify-content: center;` : null}
  ${props => props.$acenter ? `align-items: center;` : null}
  ${props => props.$between ? `justify-content: space-between;` : null}
  ${props => props.$around ? `justify-content: space-around;` : null}
  ${props => props.$evenly ? `justify-content: space-evenly;` : null}
  ${props => props.$stretch ? `align-items: stretch;` : null}
  ${props => props.$column ? `flex-direction: column;` : null}
  ${props => props.$wrap ? `flex-wrap: wrap;` : null}

  ${props => props.$expandLimite ? `
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  ` : ``}
`;

export const NkSplitContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const NkSplitColumn = styled.div`
  width: calc(50% - 20px);
  height: 100%;
`;

export const WrappedListContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
`;

export const RelativeBox = styled.div`
  position: relative;
`;

export const ContextMenuManager = styled.div`
  position: fixed;
  ${props => props.$top ? `top: ${props.$top};` : ``};
  ${props => props.$left ? `left: ${props.$left};` : ``};
  ${props => props.$right ? `right: ${props.$right};` : ``};
  ${props => props.$bottom ? `bottom: ${props.$bottom};` : ``};
  ${props => props.$width ? `width: ${props.$width};` : ``};
  ${props => props.$height ? `height: ${props.$height};` : ``};
  z-index: 999999;
`;

export const PageTitle = styled.h1`
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: ${getThemes().titleFont};
  color: ${getThemes().light};

  ${props => props.$center ? `text-align: center;` : null}
  ${props => props.$xl ? `font-size: 2.5rem;` : null}
`;

export const BoxContainer = styled.div`
  width: 100%;
  padding: ${props => props.padding || "0"};
  margin: ${props => props.margin || "0"};
`;
