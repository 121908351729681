import React from 'react';
import { motion } from 'framer-motion';

const Helps = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      Assistance page: gestion des bugs et reports, gestion des demandes d'assistance du personnel restaurateurs
    </motion.div>
  );
};

export default Helps;