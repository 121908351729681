import React, { useContext } from 'react';
import { Fragment } from 'react';
import {
  NkFormSection,
  NkFormSectionTitle,
  NkHSeparator,
} from '../../styles/Forms.style';
import { NkDuoBtns, NkInput } from '../../components/Forms';
import { BoxContainer, NkContainer, PageTitle } from '../../styles/Styles';
import { NENameInput } from '../../styles/environment/EnvItems/NewEnv.style';
import { AppContext } from '../../inc/AppContext';
import { NkBtn } from '../../components/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { nkFetch } from '../../inc/function.inc';
import { useNavigate } from 'react-router-dom';

const NewEnv = () => {
  const app = useContext(AppContext);
  const nav = useNavigate();

  const [name, setName] = React.useState('');

  const [fname, setFname] = React.useState('');
  const [lname, setLname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [btn, setBtn] = React.useState('Terminer');

  return (
    <NkContainer $expandLimite $flex $center>
      <BoxContainer>
        <PageTitle $center>Nouvel Environnement</PageTitle>

        <BoxContainer padding="25px 0 25px 0">
          <NENameInput
            placeholder="Nom du restaurant ..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </BoxContainer>

        <BoxContainer padding="25px 0 25px 0">
          <NkFormSectionTitle>Admin Login</NkFormSectionTitle>
          <div className="nk-row mb">
            <div className="nk-col-6">
              <NkInput
                label="Prénom"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
              />
            </div>
            <div className="nk-col-6">
              <NkInput
                label="Nom"
                value={lname}
                onChange={(e) => setLname(e.target.value)}
              />
            </div>
          </div>
          <div className="nk-row">
            <div className="nk-col-6">
              <NkInput
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="nk-col-6">
              <NkInput
                label="Mot de passe"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
        </BoxContainer>

        <BoxContainer padding="25px 0 25px 0">
          <div className="nk-col-12">
            <NkBtn
              name={btn}
              type="primary"
              fill={true}
              onClick={async () => {
                setBtn(<FontAwesomeIcon icon={solid('circle-notch')} spin />);

                try {
                  const r = await nkFetch('/own/restaurant', 'POST', {
                    restaurantName: name,
                    fname: fname,
                    lname: lname,
                    email: email,
                    password: password,
                  });

                  if (r.status === 200) {
                    app.makeAlert('Environnement ajouté.', 'success');
                    nav('/environments');
                  } else {
                    app.makeAlert(
                      r.message || "Erreur lors de l'ajout de l'environnement.",
                      'danger'
                    );
                  }
                } catch (e) {
                  app.makeAlert(
                    "Impossible d'ajouter l'environnement.",
                    'danger'
                  );
                }

                setBtn('Terminer');
              }}
            />
          </div>
        </BoxContainer>
      </BoxContainer>
    </NkContainer>
  );
};

export default NewEnv;
