import React, { useEffect } from 'react';
import { AppContext } from '../inc/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NkAlertBox, NkAlertCanvas, NkAlertCloseBtn, NkAlertMsg } from '../styles/Alerts.style';
import { useNavigate } from 'react-router-dom';

const Alert = (props)=>{
  const [alert, setAlert] = React.useState(props.item);

  const nav = useNavigate();

  useEffect(()=>{
    if(alert){
      setTimeout(()=>{
        setAlert(null);
      }, 10000);
    }
  }, [alert]);

  if(alert == null) return null;
  return(
    <NkAlertBox
      $pointer={alert.url}
      $danger={alert.danger}
      $type={alert.type}
      onClick={(e)=>{
        if(alert.url){
          nav(alert.url);
          setAlert(null);
        }
      }}
    >
      <NkAlertMsg className="noselect" $danger={alert.danger} $type={alert.type}>{alert.content}</NkAlertMsg>
      <NkAlertCloseBtn $danger={alert.danger} $type={alert.type} onClick={(e)=>{
        e.stopPropagation();
        setAlert(null);
      }}>
        <FontAwesomeIcon icon={solid('times')} />
      </NkAlertCloseBtn>
    </NkAlertBox>
  );
}

const AlertsCanvas = () => {
  const app = React.useContext(AppContext);

  return (
    <NkAlertCanvas>
      {
        app.alerts.map((item, index) => {
          return <Alert key={index} item={item} />
        })
      }
    </NkAlertCanvas>
  );
};

export default AlertsCanvas;