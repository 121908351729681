import { styled } from "styled-components";
import { getThemes } from "./Themes";

export const NkSquareMediaBox = styled.div`
  transition: 0.15s;
  position: relative;
  width: ${props => props.$width ?? "150px"};
  height: ${props => props.$height ?? "150px"};
  background: ${props => getThemes(props.$theme).layerDark};
  border: 1px solid ${props => getThemes(props.$theme).lightOver};
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: ${props => props.$mb}px;
  display: flex;
  align-items: center;
  justify-content: center;

  .filename{
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.9rem;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: ${getThemes().softDark};
    color: ${getThemes().light};
    font-family: ${getThemes().currentFont};
    font-weight: 400;
    max-width: 80%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:active{
  transform: scale(0.95);
  }

  ${props => props["aria-disabled"] ? `
  opacity: 0.5;
  pointer-events: none;
  ` : null}

  ${props => props.$flexMode ? `
  flex-grow: 1;
  margin: 10px;
  ` : null}
`;

export const NkSquareImg = styled.img`
  width: ${props => props.$empty ? "70%" : "100%"};
  height: ${props => props.$empty ? "70%" : "100%"};
  object-fit: ${props => props.$empty ? "contain" : "cover"};
`;

export const NkSquareImportBtn = styled.button`
  transition: 0.2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: ${props => getThemes(props.$theme).softDarkGlass};
  border: none;
  z-index: 1;
  opacity: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;

  img{
  width: 50px;
  height: 50px;
  }

  &:hover{
  opacity: 1;
  }
`;

export const NkSquareDropLayer = styled.div`
  transition: 0.2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: ${props => getThemes(props.$theme).softDarkGlass};
  border: none;
  z-index: 1;
  opacity: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  pointer-events: none;

  font-size: 1rem;
  font-family: ${getThemes().currentFont};
  color: ${props => getThemes(props.$theme).light};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.$dragging ? `
    opacity: 1;
  ` : null}
`;

export const NkFieldBox = styled.div`
  position: relative;
  width: 100%;

  label{
    /* margin-left: 15px; */
    color: ${props => getThemes(props.$theme).light};
    font-family: ${getThemes().currentFont};
    font-size: 0.9rem;
    margin-bottom: 8px;
    display: block;
    z-index: 1;

    ${props => props.$labelMono ? `
      font-family: ${getThemes().monoFont};
    ` : ``}
  }

  .nk-input-cage{
    position: relative;

    &.suffix{
      display: flex;
      align-items: stretch;
      justify-content: start;
    }

    .nk-plugs-box{
      transition: 0.15s;
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: end;
      opacity: 0;

      ${props => props.$area ? `
      top: 6px;
      transform: none;
      ` : null}
    }
  }

  ${props => props.$mb ? `
    margin-bottom: ${props.$mb}px;
  ` : null}

  &:hover{
    .nk-input-cage{
      .nk-plugs-box{
      opacity: 1;
      }
    }
  }
`;

export const NkInputStyle = styled.input`
  transition: 0.15s;
  border: 1px solid transparent;
  background-color: ${props => getThemes(props.$theme).layerDark};
  padding: 12px 15px;
  font-size: 0.9rem;
  border-radius: 10px;
  color: ${props => getThemes(props.$theme).light};
  font-family: ${getThemes().currentFont};
  width: 100%;
  box-shadow: 0 0 0 0 ${props => getThemes(props.$theme).primaryColorOver};
  opacity: 0.75;
  border: .5px solid ${getThemes().layerCardStroke};
  // delete arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:hover{
    opacity: 1;
    border: 1px solid ${props => getThemes(props.$theme).primaryColorAlpha};
  }

  &:focus{
    opacity: 1;
    border: 1px solid ${props => getThemes(props.$theme).primaryColor};
    box-shadow: 0 0 0 3px ${props => getThemes(props.$theme).primaryColorOver};
    color: ${props => getThemes(props.$theme).light};
  }

  &:disabled{
    opacity: 0.5;
  }

  ${props => props.$size === "sm" ? `
    padding: 8px 10px;
    border-radius: 8px;
  ` : null}

  ${props => props.$suffix ? `
    border-radius: 10px 0 0 10px;
  `: null}

  ${props => props.$mono ? `
    font-family: ${getThemes().monoFont};
  ` : ``}
`;

export const NkInputSuffix = styled.div`
  padding: 0 10px;
  border-radius: 0 10px 10px 0;
  background-color: ${props => getThemes(props.$theme).layerDark};
  color: ${props => getThemes(props.$theme).light};
  border: 1px solid ${props => getThemes(props.$theme).layerCardStroke};
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-family: ${getThemes().currentFont};
  cursor: pointer;
`;



export const NkAreaStyle = styled.textarea`
  transition: 0.15s;
  border: 1px solid transparent;
  background-color: ${props => getThemes(props.$theme).layerDark};
  padding: 12px 15px;
  font-size: 0.9rem;
  border-radius: 10px;
  color: ${props => getThemes(props.$theme).light};
  font-family: ${getThemes().currentFont};
  width: 100%;
  box-shadow: 0 0 0 0 ${props => getThemes(props.$theme).primaryColorOver};
  opacity: 0.75;
  resize: none;
  height: 6rem;
  border: .5px solid ${getThemes().layerCardStroke};

  // remove scroll bar on all browsers
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover{
    opacity: 1;
    border: 1px solid ${props => getThemes(props.$theme).primaryColorAlpha};
  }

  &:focus{
    opacity: 1;
    border: 1px solid ${props => getThemes(props.$theme).primaryColor};
    box-shadow: 0 0 0 3px ${props => getThemes(props.$theme).primaryColorOver};
    color: ${props => getThemes(props.$theme).light};
  }

  &:disabled{
    opacity: 0.5;
  }
`;

export const NkSelectStyle = styled.select`
  transition: 0.15s;
  border: 1px solid transparent;
  background-color: ${props => getThemes(props.$theme).layerDark};
  padding: 12px 15px;
  font-size: 0.9rem;
  border-radius: 10px;
  color: ${props => getThemes(props.$theme).light};
  font-family: ${props => props.$mono ? getThemes(props.$theme).monoFont : getThemes(props.$theme).currentFont};
  width: ${props => props.$width ?? '100%'};
  box-shadow: 0 0 0 0 ${props => getThemes(props.$theme).primaryColorOver};
  opacity: 0.75;
  // delete arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  border: .5px solid ${getThemes().layerCardStroke};

  /* change scrollbar width */
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${props => getThemes(props.$theme).primaryColor};
    border-radius: 2px;
  }

  ${props => props.$size === "sm" ? `
    padding: 8px 15px;
    font-size: 0.8rem;
    border-radius: 5px;
    height: 34px;
  ` : null}

  &:hover{
    opacity: 1;
    border: 1px solid ${props => getThemes(props.$theme).primaryColorAlpha};
  }

  &:focus{
    opacity: 1;
    border: 1px solid ${props => getThemes(props.$theme).primaryColor};
    box-shadow: 0 0 0 3px ${props => getThemes(props.$theme).primaryColorOver};
    color: ${props => getThemes(props.$theme).light};
  }

  &:disabled{
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const NkSelectArrow = styled.div`
  transition: 0.25s;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 1;
  color: ${props => getThemes(props.$theme).light};
  pointer-events: none;

  ${props => props.$open ? `
    transform: translateY(-50%) rotate(-180deg);
  ` : null}
`;

export const NkCheckBoxRow = styled.button`
  transition: 0.15s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 5px 0;
  width: 100%;
  opacity: ${props => props.$checked || props.$opaque ? 1 : 0.6};
  
  p{
    font-size: 0.9rem;
    line-height: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => getThemes(props.$theme).light};
    font-family: ${getThemes().currentFont};
    /* margin-left: 15px; */
  }

  &:hover{
    opacity: 1;
  }

  ${props => props["aria-disabled"] ? `
    opacity: 0.25;
    pointer-events: none;
  ` : null}
`;

export const NkCheckBoxPill = styled.div`
  transition: 0.15s;
  background-color: ${props => props.$checked ? getThemes(props.$theme).primaryColor : getThemes(props.$theme).softDark};
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .nk-icon{
    font-size: 14px;
    color: ${props => getThemes(props.$theme).light};
    opacity: ${props => props.$checked ? 1 : 0};
  }
`;

export const NkCheckBoxSwitch = styled.div`
  transition: 0.25s;
  background-color: ${props => props.$checked ? getThemes(props.$theme).primaryColor : getThemes(props.$theme).layerCardStroke};
  width: 48px;
  height: 24px;
  border-radius: 500px;
  position: relative;
  border: 1px solid ${getThemes().layerCardStroke};

  .nk-pill{
    transition: 0.25s;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${props => getThemes(props.$theme).light};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${props => props.$checked ? "calc(100% - 21px)" : "1px"};
  }
`;



export const NkRadioContainer = styled.div`
  width: 100%;
  height: 56px;
  padding: 2px;
  /* background-color: ${props => getThemes(props.$theme).layerDark}; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${props => props["aria-disabled"] ? `
    opacity: 0.5;
    pointer-events: none;
  ` : null}
`;

export const NkRadioHoverContainer = styled.div`
  position: absolute;
  width: calc(100% - 4px);
  height: 100%;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
`;

export const NkRadioHover = styled.div`
  transition: 0.2s;
  position: absolute;
  left: ${props => (100/parseInt(props.$length)*props.$selected)}%;
  top: 50%;
  transform: translateY(-50%);
  height: calc(100% - 5px);
  width: ${props => 100/parseInt(props.$length)}%;
  border-bottom: 2px solid ${props => getThemes(props.$theme).primaryColor};
  z-index: 0;
`;

export const NkRadioBtn = styled.button`
  transition: 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => 100/parseInt(props.$length)}%;
  height: 100%;
  border-radius: 10px;
  background-color: transparent;
  color: ${props => getThemes(props.$theme).light};
  font-family: ${getThemes().currentFont};
  font-size: 0.9rem;
  position: relative;
  white-space: nowrap;
  z-index: 1;
  flex-grow: 0;
  ${props => !props.$selected ? `opacity: 0.5;` : null}

  &:hover{
    opacity: 1;
  }
`;

export const NkHSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${getThemes().layerCardStroke};
  ${props => props.marg ? `margin: ${props.marg ?? 0}px 0;` : null}
  pointer-events: none;
`;

export const NkVSeparator = styled.div`
  width: 1px;
  background-color: ${getThemes().layerCardStroke};
  ${props => props.marg ? `margin: 0 ${props.marg ?? 0}px;` : null}
  pointer-events: none;
  flex-shrink: 0;

  ${props => !props.$noh ? `
    height: 100%;
  ` : ``}
`;

export const NkFormSection = styled.div`
  width: 100%;
  padding: ${props => props.$sm ? "15" : "25"}px;

  ${props => props.$end ? `
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `:null}

  ${props => props.$center ? `
    display: flex;
    align-items: center;
    justify-content: center;
  `:null}

  ${props => props.$btSticky ? `
    position: sticky;
    bottom: 0;
    background-color: ${getThemes().softDark};
    border-top: 1px solid ${getThemes().layerCardStroke};
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 10;
    
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      background-color: ${getThemes().softDarkAlpha};
    }
  `:null}

  ${props => props.$between ? `
    display: flex;
    align-items: center;
    justify-content: space-between;
  `:null}
`;

export const NkFormSectionTitle = styled.h3`
  font-family: ${getThemes().currentFont};
  font-size: 1.2rem;
  color: ${getThemes().light};
  margin: 0;
  margin-bottom: 25px;
  font-weight: 500;

  ${props => props.$tBar ? `
    padding: 15px;
    border-radius: 10px;
    background-color: ${getThemes().layerDarkGlass};
    box-shadow: 0 10px 15px -5px ${getThemes().dark};
    width: 100%;
    border: 1px solid ${getThemes().layerCardStroke};
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin-bottom: 5px;
  ` : ``}
`;

export const NkFormSectionAlert = styled.p`
  width: 100%;
  font-family: ${getThemes().currentFont};
  font-size: 0.9rem;
  color: ${getThemes().light};
  opacity: 0.75;
  margin-bottom: 25px;
  font-weight: 500;
  line-height: 1rem;

  ${props => props.$popup ? `
    text-align: center;
    font-size: 0.8rem;
  ` : ``}

  ${props => props.$danger ? `
    color: ${getThemes().dangerColor};
  ` : ``}
`;

export const SearchBarStyle = styled.input`
  transition: 0.15s;
  width: 100%;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 10px;
  font-family: ${getThemes().currentFont};
  background-color: ${getThemes().layerDarkGlass};
  border: 1px solid transparent;
  color: ${getThemes().light};
  opacity: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 0 0 0 ${props => getThemes(props.$theme).primaryColorOver};

  &:hover{
    border: 1px solid ${props => getThemes(props.$theme).primaryColorAlpha};
  }

  &:focus{
    outline: none;
    opacity: 1;
    border: 1px solid ${props => getThemes(props.$theme).primaryColor};
    box-shadow: 0 0 0 3px ${props => getThemes(props.$theme).primaryColorOver};
  }

  &::placeholder{
    color: ${getThemes().lightAlpha};
  }

  ${props => props.$mr ? `
    margin-right: ${props.$mr}px;
  ` : null}
`;
