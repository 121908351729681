import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const randomFills = (length = 24) => {
  let tmp = [];
  for (let i = 0; i < length; i++) {
    tmp.push(Math.floor(Math.random() * 100));
  }
  return tmp;
};

const makeHours = (max = 24) => {
  let tmp = [];
  for (let i = max; i > 0; i--) {
    tmp.push(i.toString() + "h");
  }
  return tmp;
};

const labels = makeHours();

export function DashboardCharts(props) {
  return <Line
    options={{
      responsive: true,
      tension: 0.4,
      maintainAspectRatio: false,
      hover: {
        intersect: false
      },
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
        y: {
          grid: {
            color: "rgba(255,255,255,0.1)",
          }
        }
      }
    }}
    data={{
      labels,
      datasets: [
        {
          fill: true,
          data: randomFills(),
          borderColor: "rgb(177, 109, 255)",
          backgroundColor: (context) => {
            const bgColor = ["rgba(177, 109, 255, 0.5)", "rgba(177, 109, 255, 0)"];
    
            if (!context.chart.chartArea) return;
    
            const { ctx, chartArea: { top, bottom } } = context.chart;
            const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
            gradientBg.addColorStop(0, bgColor[0]);
            gradientBg.addColorStop(1, bgColor[1]);
            return gradientBg;
          }
        }
      ]
    }}
  />;
}
