import React, { useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppContext } from './inc/AppContext';
import reportWebVitals from './reportWebVitals';
import { CanvasPopup, ChoicePopup } from './components/Popup';
import { Ghost, VisibleGhost } from './components/Ghost';
import { eraseCookie, getLocalStorage, setLocalStorage } from './inc/function.inc';
import { ContextMenuManager } from './styles/Styles';
import * as Sentry from "@sentry/react";

import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";

// Sentry.init({
//   dsn: "https://18e9bf1ddb1bf1ed9f146163301b38f1@o4506087319863296.ingest.sentry.io/4506087327858688",
// });



var mouseX = 0;
var mouseY = 0;

document.addEventListener("mousemove", (e) => {
  mouseX = e.clientX;
  mouseY = e.clientY;
});

const AppContextEmbed = () => {

  // if connected with http redirect to https
  // if (window.location.protocol === "http:" && window.location.hostname !== "localhost") {
  //     window.location.href = window.location.href.replace("http:", "https:");
  // }

  const [theme, setTheme] = React.useState(getLocalStorage("theme") || {
    mode: "dark",
    color: "classic",
  });
  const [ghostContent, setGhostContent] = React.useState(null);
  const [ghostFilledContent, setGhostFilledContent] = React.useState(null);

  const [popupCanvas, setPopupCanvas] = React.useState(null);
  const [alerts, setAlerts] = React.useState([]);
  const [contextMenu, setContextMenu] = React.useState(null);

  const [access, setAccess] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [appOptions, setAppOptions] = React.useState(getLocalStorage("app_options") || {});

  const app = {theme,setTheme,ghostContent,setGhostContent,ghostFilledContent,setGhostFilledContent,popupCanvas,setPopupCanvas,alerts,setAlerts,contextMenu,setContextMenu,access,setAccess,user,setUser,appOptions,setAppOptions};



  /* APP OPTIONS */
  app.setOption = useCallback((key, value) => {
    setAppOptions({...appOptions, [key]: value});
    setLocalStorage("app_options", {...appOptions, [key]: value});
  }, [appOptions]);

  app.eraseOption = useCallback((key) => {
    const newOptions = {...appOptions};
    delete newOptions[key];
    setAppOptions(newOptions);
    setLocalStorage("app_options", newOptions);
  }, [appOptions]);

  app.getOption = useCallback((key) => {
    return appOptions[key];
  }, [appOptions]);



  /* CONTEXT MENU */
  app.makeContextMenu = useCallback((component) => {
    let directionY = "top";
    let directionX = "left";

    console.log(window.innerHeight/1.5, mouseY);

    if (mouseY > (window.innerHeight/1.5)) directionY = "bottom";
    if (mouseX > (window.innerWidth/1.5)) directionX = "right";

    setGhostContent(<Ghost zindex={99999} onClick={app.resetContextMenu}/>);
    setContextMenu(
      <ContextMenuManager
        $top={directionY === "top" ? String(mouseY) + "px" : null}
        $left={directionX === "left" ? String(mouseX) + "px" : null}
        $bottom={directionY === "bottom" ? String(window.innerHeight - mouseY) + "px" : null}
        $right={directionX === "right" ? String(window.innerWidth - mouseX) + "px" : null}
      >
        {component}
      </ContextMenuManager>
    );
  }, [contextMenu]);

  app.resetContextMenu = useCallback(() => {
    setContextMenu(null);
    setGhostContent(null);
  }, [contextMenu]);

  /* CHOICE POPUP */
  app.makeChoicePopup = useCallback((title, description, params={}) => {
    setGhostFilledContent(<VisibleGhost onClick={() => setPopupCanvas(null)} />);
    setPopupCanvas(<ChoicePopup
      title={title}
      description={description}
      alert={params.alert}
      lbtn={params.lbtn}
      rbtn={params.rbtn}
    />);
  }, [popupCanvas, ghostContent]);

  app.resetChoicePopup = useCallback(() => {
    setGhostFilledContent(null);
    setPopupCanvas(null);
  }, [popupCanvas, ghostContent]);



  /* CANVAS POPUP */
  app.makeCanvasPopup = useCallback((title, component, subTitle=null) => {
    setGhostFilledContent(<VisibleGhost
      onClick={() => setPopupCanvas(null)}
    />);
    setPopupCanvas(<CanvasPopup
      title={title}
      component={component}
      subTitle={subTitle}
    />);
  }, [popupCanvas, ghostContent]);

  app.resetCanvasPopup = useCallback(() => {
    setGhostFilledContent(null);
    setPopupCanvas(null);
  }, [popupCanvas, ghostContent]);



  /* ALERTS */
  app.makeAlert = useCallback((content, type="info", url=null) => {
    setAlerts([...alerts, {content, type, url}]);
  }, [alerts]);


  const [queryClient] = React.useState(() => new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (query.meta?.errorMessage) {
          console.log(query.meta.errorMessage);
          if(app.makeAlert) app.makeAlert(String(query.meta.errorMessage), true);
        }
        if(query.meta?.notLogin){
          if(error?.data?.status === 401){
            eraseCookie("token");
            window.location.reload();
          }
        }
      },
    }),
  }));

  return(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={"An error has occured"}>
        <AppContext.Provider value={app}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </AppContext.Provider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppContextEmbed />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();