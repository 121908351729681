import { styled } from "styled-components";
import { getThemes } from "./Themes";

const NkAlertCanvas = styled.div`
  position: fixed;
  top: 25px;
  left: 25px;
  width: 400px;
  max-width: calc(100% - 50px);
  z-index: 1000;
`;

const NkAlertBox = styled.div`
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 5px 25px -10px ${props => getThemes(props.$theme).darkGlass};

  ${props => props.$pointer ? `
    cursor: pointer;
  ` : null}

  ${props => props.$type === "info" || !props.$type ? `
    background: ${getThemes(props.$theme).layerCardGradientGlass};
    border: 0.5px solid ${getThemes(props.$theme).layerCardStroke};
  ` : null}
  ${props => props.$type === "success" ? `
    background: ${getThemes(props.$theme).layerCardGradientSuccess};
    border: 0.5px solid ${getThemes(props.$theme).layerCardStrokeSuccess};
  ` : null}
  ${props => props.$type === "danger" ? `
    background: ${getThemes(props.$theme).layerCardGradientDanger};
    border: 0.5px solid ${getThemes(props.$theme).layerCardStrokeDanger};
  ` : null}
`;

const NkAlertMsg = styled.div`
  color: ${props => getThemes(props.$theme).light};
  font-family: ${getThemes().currentFont};
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  margin-right: 10px;
  word-break: break-word;

  ${props => props.$type === "info" || !props.$type ? `
    color: ${getThemes(props.$theme).light};
  ` : null}
  ${props => props.$type === "success" ? `
    color: ${getThemes(props.$theme).checkColor};
  ` : null}
  ${props => props.$type === "danger" ? `
    color: ${getThemes(props.$theme).errorColor};
  ` : null}
`;

const NkAlertCloseBtn = styled.button`
  transition: 0.15s;
  background-color: transparent;
  border: 0.5px solid ${props => getThemes(props.$theme).layerCardStroke};
  width: 30px;
  height: 30px;
  color: ${props => getThemes(props.$theme).light};
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-shrink: 0;

  ${props => props.$type === "info" || !props.$type ? `
    border: 0.5px solid ${getThemes(props.$theme).layerCardStroke};
    color: ${getThemes(props.$theme).light};
  ` : null}
  ${props => props.$type === "success" ? `
    border: 0.5px solid ${getThemes(props.$theme).layerCardStrokeSuccess};
    color: ${getThemes(props.$theme).checkColor};
  ` : null}
  ${props => props.$type === "danger" ? `
    border: 0.5px solid ${getThemes(props.$theme).layerCardStrokeDanger};
    color: ${getThemes(props.$theme).errorColor};
  ` : null}

  &:hover{
    ${props => props.$type === "info" || !props.$type ? `
      background-color: ${getThemes(props.$theme).layerCardStroke};
    ` : null}
    ${props => props.$type === "success" ? `
      background-color: ${getThemes(props.$theme).checkColorOver};
    ` : null}
    ${props => props.$type === "danger" ? `
      background-color: ${getThemes(props.$theme).dangerColorOver};
    ` : null}
  }

  &:active{
    transform: scale(0.9);
  }
`;

export { NkAlertCanvas, NkAlertBox, NkAlertMsg, NkAlertCloseBtn };