import { styled } from "styled-components";
import { getThemes } from "../../styles/Themes";



export const StatsRowBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  ${props => props.$center ? `
    justify-content: center;
    align-items: center;
  ` : ``}
`;

export const StatBox = styled.div`
  width: 25%;
  margin-right: 15px;
  height: 170px;
  background-color: ${getThemes().softDark};
  border-radius: 15px;
  flex-shrink: 1;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  color: ${getThemes().light};
  font-family: ${getThemes().currentFont};

  &:last-child {
    margin-right: 0;
  }
`;

export const SBLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.$end ? `
    justify-content: flex-end;
  ` : ``}
`;

export const SBBoxLine = styled.div`
  width: 100%;
`;

export const SBTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  opacity: 0.5;
`;

export const SBIcon = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${props => getThemes()[props.$color+"ColorOver"]};
  color: ${props => getThemes()[props.$color+"Color"]};
  /* background-color: ${getThemes().lightOver}; */
  /* color: ${getThemes().light}; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SBValue = styled.p`
  font-size: 2rem;
  font-weight: 700;
`;

export const SBValueSmall = styled.p`
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.5;
  width: 100%;
  text-align: right;
`;



export const GraphContainer = styled.div`
  width: calc(60% - 15px);
  height: 410px;
  background-color: ${getThemes().softDark};
  border-radius: 15px;
  padding: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${props => props.$small ? `
    width: 40%;
  ` : ``}

  &:last-child {
    margin-right: 0;
  }
`;

export const GBTitle = styled.p`
  width: 100%;
  font-size: 1.2rem;
  font-weight: 400;
  opacity: 0.5;
  margin-bottom: 25px;
  color: ${getThemes().light};
  font-family: ${getThemes().currentFont};
`;

export const GBGraphBox = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* padding: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
`;



export const BugReportContainer = styled(GBGraphBox)`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.$between ? `space-between` : `flex-start`};
  align-items: flex-start;
`;

export const BugReportBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;



export const BRImageBox = styled.div`
  flex-shrink: 0;
  margin-right: 15px;
`;

export const BRImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;



export const BRInfosBox = styled.div`
  width: calc(100% - 45px);
  flex-shrink: 1;
`;

export const BRTitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const BRDate = styled.small`
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.5;
  color: ${getThemes().light};
  font-family: ${getThemes().currentFont};
  flex-shrink: 0;
  margin-left: 10px;
`;

export const BRTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${getThemes().light};
  font-family: ${getThemes().currentFont};
  width: 100%;
  line-height: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
`;

export const BRText = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.5;
  color: ${getThemes().light};
  font-family: ${getThemes().currentFont};
  width: 100%;
  line-height: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  white-space: nowrap;
`;



export const LinkAreaBox = styled.div`
  width: 100%;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RedirectBtnImage = styled.img`
  width: 75%;
  height: 75%;
  object-fit: contain;
`;

export const RedirectBtnText = styled.p`
  transition: 0.25s;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;

  font-size: 1rem;
  font-family: ${getThemes().currentFont};
  color: ${getThemes().light};
  pointer-events: none;
  opacity: 0;
`;

export const RedirectBtnStyle = styled.a`
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 0 25px;
  position: relative;
  cursor: pointer;

  &:hover{
    transform: scale(1.25);

    ${RedirectBtnText}{
      opacity: 1;
      top: 110%;
    }
  }
`;