import { styled } from "styled-components";
import { getThemes } from "./Themes";



const EllipseContainer = styled.div`
  position: relative;
`;

const EllipseBtn = styled.button`
  transition: 0.15s;
  display: flex;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
  color: ${getThemes().light};
  background-color: transparent;
  font-size: 1rem;
  position: relative;

  &:hover{
    background-color: ${getThemes().layerCardStroke};
  }

  &:active{
    transform: scale(0.9);
    background-color: ${getThemes().light};
    color: ${getThemes().dark};
  }
`;

const EllipseContextMenu = styled.div`
  transition: 0.15s;
  ${props => !props.$noabs ? `
    position: absolute;
    top: ${props.$top ?? "110%"};
    left: ${props.$left ?? "0"};
  ` : ``}
  width: ${props => props.$width ?? "180px"};
  max-height: 185px;
  padding: 5px;
  border-radius: 5px;
  background-color: ${getThemes().layerDarkGlass};
  box-shadow: 0 10px 15px -5px ${getThemes().dark};
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: ${props => props.$zindex ?? "200"};
  opacity: 0;
  pointer-events: none;
  overflow-x: hidden;
  overflow-y: auto;

  // remove scroll bar on all browsers
  /* scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  } */

  /* scroll bar width 2px */
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* scroll bar primary color */
  &::-webkit-scrollbar-thumb {
    background: ${props => getThemes(props.$theme).primaryColor};
  }

  /* arrow height to 5px */
  &::-webkit-scrollbar-button {
    height: 10px;
  }

  ${props => props.$open ? `
    opacity: 1;
    pointer-events: all;
  ` : null}
`;

const EllipseContextMenuItem = styled.button`
  transition: 0.1s;
  width: 100%;
  padding: 8px 15px;
  border-radius: 3px;
  background-color: transparent;
  color: ${getThemes().light};
  font-size: 0.9rem;
  font-weight: 600;
  font-family: ${getThemes().currentFont};
  opacity: 0.85;
  margin-bottom: 2px;
  text-align: left;

  span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1rem;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &:hover{
    opacity: 1;
    background-color: ${getThemes().layerCardStroke};
  }

  &:active{
    transform: scale(0.98);
  }

  ${props => props.$danger ? `
    color: ${getThemes().dangerColor};

    &:hover{
      background-color: ${getThemes().dangerColor};
      color: ${getThemes().light};
    }
  ` : null}

  ${props => props.$between ? `
    display: flex;
    justify-content: space-between;
    align-items: center;
  ` : null}
`;

const NkBtnStyle = styled.button`
  transition: 0.15s;
  padding: 12px 25px;
  background-color: ${props => props.$less ? getThemes(props.$theme).lightOver : getThemes(props.$theme).light};
  border-radius: 10px;
  font-family: ${props => props.$mono ? getThemes(props.$theme).monoFont : getThemes(props.$theme).currentFont};
  font-weight: bold;
  font-size: 1rem;
  color: ${props => props.$less ? getThemes(props.$theme).light : getThemes(props.$theme).dark};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.$superLess ? `
    background-color: transparent;
    color: ${getThemes(props.$theme).light};
    opacity: 0.5;
  ` : null}

  &:hover{
    background-color: ${props => props.$less ? getThemes(props.$theme).light : getThemes(props.$theme).lightOver};
    color: ${props => props.$less ? getThemes(props.$theme).dark : getThemes(props.$theme).light};
    opacity: 1;
  }

  // props.type === "primary": background primaryColor & color light
  ${props => props.$type === "primary" ? `
    background-color: ${props.$less ? getThemes(props.$theme).primaryColorOver : getThemes(props.$theme).primaryColor};
    color: ${props.$less ? getThemes(props.$theme).primaryColor : getThemes(props.$theme).light};

    &:hover{
      background-color: ${props.$less ? getThemes(props.$theme).primaryColor : getThemes(props.$theme).primaryColorOver};
      color: ${props.$less ? getThemes(props.$theme).light : getThemes(props.$theme).primaryColor};
    }
  ` : null}
  // props.type === "secondary": background secondaryColor & color light
  ${props => props.$type === "secondary" ? `
    background-color: ${props.$less ? getThemes(props.$theme).secondaryColorOver : getThemes(props.$theme).secondaryColor};
    color: ${props.$less ? getThemes(props.$theme).secondaryColor : getThemes(props.$theme).light};

    &:hover{
      background-color: ${props.$less ? getThemes(props.$theme).secondaryColor : getThemes(props.$theme).secondaryColorOver};
      color: ${props.$less ? getThemes(props.$theme).light : getThemes(props.$theme).secondaryColor};
    }
  ` : null}
  // props.type === "danger": background dangerColor & color light
  ${props => props.$type === "danger" ? `
    background-color: ${props.$less ? getThemes(props.$theme).dangerColorOver : getThemes(props.$theme).dangerColor};
    color: ${props.$less ? getThemes(props.$theme).dangerColor : getThemes(props.$theme).light};

    ${props.$superLess ? `
      background-color: transparent;
      color: ${getThemes(props.$theme).dangerColor};
      opacity: 0.5;
    ` : ``}

    &:hover{
      background-color: ${props.$less ? getThemes(props.$theme).dangerColor : getThemes(props.$theme).dangerColorOver};
      color: ${props.$less ? getThemes(props.$theme).light : getThemes(props.$theme).dangerColor};
    }
  ` : null}
  // props.type === "warning": background warningColor & color light
  ${props => props.$type === "warning" ? `
    background-color: ${props.$less ? getThemes(props.$theme).warningColorOver : getThemes(props.$theme).warningColor};
    color: ${props.$less ? getThemes(props.$theme).warningColor : getThemes(props.$theme).dark};

    &:hover{
      background-color: ${props.$less ? getThemes(props.$theme).warningColor : getThemes(props.$theme).warningColorOver};
      color: ${props.$less ? getThemes(props.$theme).dark : getThemes(props.$theme).warningColor};
    }
  ` : null}
  // props.type === "check": background checkColor & color light
  ${props => props.$type === "check" ? `
    background-color: ${props.$less ? getThemes(props.$theme).checkColorOver : getThemes(props.$theme).checkColor};
    color: ${props.$less ? getThemes(props.$theme).checkColor : getThemes(props.$theme).dark};

    &:hover{
      background-color: ${props.$less ? getThemes(props.$theme).checkColor : getThemes(props.$theme).checkColorOver};
      color: ${props.$less ? getThemes(props.$theme).dark : getThemes(props.$theme).checkColor};
    }
  ` : null}
  // props.type === "info": background infoColor & color light
  ${props => props.$type === "info" ? `
    background-color: ${props.$less ? getThemes(props.$theme).infoColorOver : getThemes(props.$theme).infoColor};
    color: ${props.$less ? getThemes(props.$theme).infoColor : getThemes(props.$theme).dark};

    &:hover{
      background-color: ${props.$less ? getThemes(props.$theme).infoColor : getThemes(props.$theme).infoColorOver};
      color: ${props.$less ? getThemes(props.$theme).dark : getThemes(props.$theme).infoColor};
    }
  ` : null}
  // props.type === "error": background errorColor & color light
  ${props => props.$type === "error" ? `
    background-color: ${props.$less ? getThemes(props.$theme).errorColorOver : getThemes(props.$theme).errorColor};
    color: ${props.$less ? getThemes(props.$theme).errorColor : getThemes(props.$theme).light};

    &:hover{
      background-color: ${props.$less ? getThemes(props.$theme).errorColor : getThemes(props.$theme).errorColorOver};
      color: ${props.$less ? getThemes(props.$theme).light : getThemes(props.$theme).errorColor};
    }
  ` : null}

  // props.$fill: fill the width of the parent
  ${props => props.$fill ? `
    width: 100%;
  ` : null}
  // props.fullFill: fill the width and height of the parent
  ${props => props.$fullFill ? `
    width: 100%;
    height: 100%;
  ` : null}

  ${props => props.$size === "lg" ? `
    padding: 15px 35px;
    font-size: 1.4rem;
  ` : null}
  ${props => props.$size === "sm" ? `
    padding: 8px 15px;
    font-size: 1rem;
    border-radius: 5px;
  ` : null}
  ${props => props.$size === "xsm" ? `
    padding: 8px 10px;
    font-size: 0.8rem;
    border-radius: 3px;
  ` : null}

  ${props => props["aria-disabled"] ? `
    opacity: 0.5;
    pointer-events: none;
  ` : null}

  &:active{
    transform: scale(0.95);
  }
`;

const PlugInputBtn = styled.button`
  transition: 0.15s;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 1rem;
  color: ${getThemes().light};
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 1px solid transparent;

  ${props => props.$ml ? `margin-left: 6px;` : null}

  &:hover{
    background: ${getThemes().layerCardStroke};
    border: .5px solid ${getThemes().layerCardStroke};
    color: ${getThemes().light};
  }

  &:active{
    background: ${getThemes().light};
    color: ${getThemes().dark};
    transform: scale(0.9);
  }
`;



const NkIconBtnStyle = styled.button`
  transition: 0.15s;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.$type ? getThemes()[props.$type + "ColorOver"] : getThemes().layerDark};
  border: .5px solid ${props => props.$type ? getThemes()[props.$type + "ColorOver"] : getThemes().layerCardStroke};
  border-radius: 5px;
  color: ${props => props.$type ? getThemes()[props.$type + "Color"] : getThemes().light};
  opacity: 0.8;

  ${props => props.$size === "lg" ? `
    height: 42px;
    width: 42px;
  ` : null}
  ${props => props.$nobg ? `
    background-color: transparent;
    border: transparent;
  ` : null}

  ${props => props.$blur ? `
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  ` : null}

  &:hover{
    opacity: 1;

    ${props => props.$nobg ? `
      background-color: ${props.$type ? getThemes()[props.$type + "ColorOver"] : getThemes().layerDark};
      border: .5px solid ${props.$type ? getThemes()[props.$type + "ColorOver"] : getThemes().layerCardStroke};
  ` : null}
  }

  &:active{
    transform: scale(0.95);
  }
`;

const NkDeployBtnStyle = styled.button`
  transition: 0.15s;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: transparent;
  opacity: 0.5;

  p{
    transition: 0.15s;
    font-size: 1.5rem;
    color: ${getThemes().light};
    font-family: ${getThemes().titleFont};
    font-weight: 700;
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    width: ${props => props.$retractWidth ?? "0"};
    text-align: left;
  }

  span{
    transition: 0.15s;
    font-size: 1.2rem;
    color: ${getThemes().light};
  }

  &:hover{
    opacity: 1;

    p{
      width: ${props => props.$deployWidth ?? "100%"};
    }
  }

  &:active{
    opacity: 0.6;
  }

  @media screen and (max-width: 1440px){
    p{
      width: auto;
    }
  }
`;



export const HeaderBtnStyle = styled.button`
  transition: 0.15s;
  width: 100%;
  background-color: transparent;
  opacity: 0.75;
  position: relative;
  /* overflow: hidden; */

  .nk-nav-btn{
    color: ${getThemes().light};
    text-decoration: none;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 25px;
    width: 100%;
    font-family: ${getThemes().currentFont};

    span.icon{
      font-size: 1rem;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &:after{
        transition: 0.15s;
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${getThemes().primaryColor};
        opacity: 0;
        transition: 0.15s;
        filter: blur(10px);
        pointer-events: none;
      }
    }

    span.name{
      margin-left: 25px;
      font-size: 0.8rem;
      transform: translateY(1px);
    }
  }

  &:hover{
    opacity: 1;
  }

  &:after{
    content: "";
    transition: 0.15s;
    position: absolute;
    bottom: 50%;
    right: 0;
    transform: translateY(50%);
    width: 4px;
    height: 70%;
    background-color: ${getThemes().primaryColor};
    border-radius: 3px 0 0 3px;
    box-shadow: 0 0 25px ${getThemes().primaryColorGlass};
    pointer-events: none;
    opacity: 0;
  }

  ${props => props.$select ? `
    opacity: 1;

    .nk-nav-btn{
      color: ${getThemes().primaryColor};

      span.icon:after{
        opacity: 1;
      }
    }

    &:after{
      opacity: 1;
    }
  ` : null}

  &:disabled {
    opacity: 0.35;
    pointer-events: none;
  }
`;



export {
  EllipseContainer,
  EllipseBtn,
  EllipseContextMenu,
  EllipseContextMenuItem,
  NkBtnStyle,
  PlugInputBtn,
  NkIconBtnStyle,
  NkDeployBtnStyle,
}