import React from 'react';
import { NkContainer, PageTitle } from '../styles/Styles';
import { BRDate, BRImageBox, BRImg, BRInfosBox, BRText, BRTitle, BRTitleBox, BugReportBox, BugReportContainer, GBGraphBox, GBTitle, GraphContainer, LinkAreaBox, RedirectBtnImage, RedirectBtnStyle, RedirectBtnText, SBBoxLine, SBIcon, SBLine, SBTitle, SBValue, SBValueSmall, StatBox, StatsRowBox } from '../styles/home/Home.style';
import { motion } from 'framer-motion';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardCharts } from './home/DashboardCharts';

import SentryLogo from "../assets/icons/sentry.svg";
import UserLogo from "../assets/icons/user.svg";
import BitBucketlogo from "../assets/icons/bitbucket.svg";
import VercelLogo from "../assets/icons/vercel-logo.png";



const StatPannel = (props) => {
  return(
    <StatBox>
      <SBLine>
        <SBTitle>{props.title}</SBTitle>
      </SBLine>
      <SBBoxLine>
        {
          props.small ? 
            <SBValueSmall>{props.small}</SBValueSmall>
          : null
        }
        <SBLine>
          <SBIcon $color={props.iconColor}>
            <FontAwesomeIcon icon={props.icon} />
          </SBIcon>
          <SBValue>{props.value}</SBValue>
        </SBLine>
      </SBBoxLine>
    </StatBox>
  );
};

const BugReport = (props) => {
  return(
    <BugReportBox>
      <BRImageBox>
        <BRImg src={props.type === "sentry" ? SentryLogo : UserLogo} alt="" />
      </BRImageBox>
      <BRInfosBox>
        <BRTitleBox>
          <BRTitle>{props.title}</BRTitle>
          <BRDate>{props.date}</BRDate>
        </BRTitleBox>
        <BRText>{props.description}</BRText>
      </BRInfosBox>
    </BugReportBox>
  );
};

const RedirectBtn = (props) => {
  return(
    <RedirectBtnStyle
      onClick={props.onClick}
      href={props.href}
      target="_blank"
    >
      <RedirectBtnImage src={props.image} alt="" />
      <RedirectBtnText>{props.text}</RedirectBtnText>
    </RedirectBtnStyle>
  );
};

const Home = () => {

  React.useEffect(() => {
    document.title = "Dashboard | Qr Eat Admin";
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <NkContainer>

        <PageTitle>Dashboard</PageTitle>

        <StatsRowBox>
          <StatPannel
            title="Chiffre d'affaire"
            value="8 000 €"
            icon={solid("chart-line")}
            iconColor="check"
          />
          <StatPannel
            title="Restaurants"
            value="234"
            small="Actifs"
            icon={solid("utensils")}
            iconColor="info"
          />
          <StatPannel
            title="Base de données"
            value="1.2Go"
            small="100Go"
            icon={solid("database")}
            iconColor="danger"
          />
          <StatPannel
            title="S3"
            value="80.2Go"
            small="2To"
            icon={solid("cloud")}
            iconColor="warning"
          />
        </StatsRowBox>

        <StatsRowBox>
          {/* last 5 bugs reports by users */}
          <GraphContainer $small>
            <GBTitle>Derniers rapports d'erreurs</GBTitle>
            <BugReportContainer $between={false}>
              <BugReport
                type="sentry"
                title="Erreur 404"
                description="La page n'existe pas"
                date="Il y a 2 jours"
              />
              <BugReport
                type="user"
                title="Probleme de connexion"
                description="Je n'arrive pas à me connecter"
                date="Il y a 5 jours"
              />
              <BugReport
                type="sentry"
                title="Erreur 404"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nisl nisl lacinia nisl, vitae aliquam nisl nisl vitae nisl. Sed euismod, nisl eget ultricies ultrices, nisl nisl lacinia nisl, vitae aliquam nisl nisl vitae nisl."
                date="Il y a 2 jours"
              />
              {/* <BugReport
                type="user"
                title="Probleme de connexionjshfqdgksfdhkjgfdshfdshjfdsqhkkhgjfdsqhgkjfdshjgkfdsqhkgjfdsqjhgfdsqhgjfdsghjkfdhgjskqfdhgjksqhgjfkdsqghj"
                description="Je n'arrive pas à me connecter"
                date="Il y a 5 jours"
              />
              <BugReport
                type="user"
                title="Probleme de connexionjshfqdgksfdhkjgfdshfdshjfdsqhkkhgjfdsqhgkjfdshjgkfdsqhkgjfdsqjhgfdsqhgjfdsghjkfdhgjskqfdhgjksqhgjfkdsqghj"
                description="Je n'arrive pas à me connecter"
                date="Il y a 5 jours"
              /> */}
            </BugReportContainer>
          </GraphContainer>
          {/* Reported bugs graph by Sentry (72h) */}
          <GraphContainer>
            <GBTitle>Rapports de bugs (24h)</GBTitle>
            <GBGraphBox>
              <DashboardCharts />
            </GBGraphBox>
          </GraphContainer>
        </StatsRowBox>

        <LinkAreaBox>
          <RedirectBtn
            image={SentryLogo}
            text="Sentry"
            href="https://sentry.io/"
          />
          <RedirectBtn
            image={BitBucketlogo}
            text="BitBucket"
            href="https://bitbucket.org/"
          />
          <RedirectBtn
            image={VercelLogo}
            text="Vercel"
            href="https://vercel.com/"
          />
        </LinkAreaBox>

      </NkContainer>
    </motion.div>
  );
};

export default Home;
