const NkDarkStyles = {
  titleFont: `"SF UI Display", "Montserrat", sans-serif`,
  currentFont: `"Montserrat", sans-serif`,
  secondFont: `"DM Sans", monospace`,
  monoFont: `Consolas, monospace`,



  // make light/dark theme
  dark: `rgb(10,5,15)`,
  darkAlpha: `rgba(10,5,15,0.4)`,
  darkGlass: `rgba(10,5,15,0.8)`,
  darkOver: `rgba(10,5,15,0.1)`,
  darkTrans: `rgba(10,5,15,0)`,

  softDark: `rgb(25,20,30)`,
  softDarkAlpha: `rgba(25,20,30, 0.4)`,
  softDarkGlass: `rgba(25,20,30, 0.8)`,
  softDarkOver: `rgba(25,20,30, 0.1)`,
  softDarkTrans: `rgba(25,20,30, 0)`,

  layerDark: `rgb(40,35,45)`,
  layerDarkAlpha: `rgba(40,35,45, 0.4)`,
  layerDarkGlass: `rgba(40,35,45, 0.8)`,
  layerDarkOver: `rgba(40,35,45, 0.1)`,
  layerDarkTrans: `rgba(40,35,45, 0)`,



  light: `rgb(255, 255, 255)`,
  lightAlpha: `rgba(255, 255,255,0.4)`,
  lightGlass: `rgba(255, 255,255,0.8)`,
  lightOver: `rgba(255, 255,255,0.1)`,
  lightTrans: `rgba(255, 255,255,0)`,

  softLight: `rgb(245,240,250)`,
  softLightAlpha: `rgba(245,240,250,0.4)`,
  softLightGlass: `rgba(245,240,250,0.8)`,
  softLightOver: `rgba(245,240,250,0.1)`,
  softLightTrans: `rgba(245,240,250,0)`,

  layerLight: `rgb(225,220,230)`,
  layerLightAlpha: `rgba(225,220,230,0.4)`,
  layerLightGlass: `rgba(225,220,230,0.8)`,
  layerLightOver: `rgba(225,220,230,0.1)`,
  layerLightTrans: `rgba(225,220,230, 0)`,



  layerCardGradient: `linear-gradient(135deg, rgba(47,52,75, 0.5) 0%, rgba(41,45,66, 0.5) 100%)`,
  layerCardStroke: `rgba(160, 176, 255, 0.1)`,
  layerCardGradientGlass: `linear-gradient(135deg, rgba(47,52,75, 0.8) 0%, rgba(41,45,66, 0.8) 100%)`,

  layerCardGradientSuccess: `linear-gradient(135deg, rgba(85, 229, 255, 0.15) 0%, rgba(122, 255, 191, 0.15) 100%)`,
  layerCardStrokeSuccess: `rgba(160, 255, 176, 0.1)`,

  layerCardGradientDanger: `linear-gradient(135deg, rgba(255, 130, 113, 0.15) 0%, rgba(255, 98, 98, 0.15) 100%)`,
  layerCardStrokeDanger: `rgb(255, 98, 98, 0.1)`,



  // danger
  dangerColor: `rgb(255, 98, 98)`,
  dangerColorAlpha: `rgba(255, 98, 98, 0.4)`,
  dangerColorGlass: `rgba(255, 98, 98, 0.8)`,
  dangerColorOver: `rgba(255, 98, 98, 0.1)`,
  // warning
  warningColor: `rgb(255, 209, 120)`,
  warningColorAlpha: `rgba(255, 209, 120, 0.4)`,
  warningColorGlass: `rgba(255, 209, 120, 0.8)`,
  warningColorOver: `rgba(255, 209, 120, 0.1)`,
  // info
  infoColor: `rgb(85, 229, 255)`,
  infoColorAlpha: `rgba(85, 229, 255, 0.4)`,
  infoColorGlass: `rgba(85, 229, 255, 0.8)`,
  infoColorOver: `rgba(85, 229, 255, 0.1)`,
  // error
  errorColor: `rgb(255, 130, 113)`,
  errorColorAlpha: `rgba(255, 130, 113, 0.4)`,
  errorColorGlass: `rgba(255, 130, 113, 0.8)`,
  errorColorOver: `rgba(255, 130, 113, 0.1)`,
  // check
  checkColor: `rgb(122, 255, 191)`,
  checkColorAlpha: `rgba(122, 255, 191, 0.4)`,
  checkColorGlass: `rgba(122, 255, 191, 0.8)`,
  checkColorShadow: `rgba(122, 255, 191, 0.2)`,
  checkColorOver: `rgba(122, 255, 191, 0.1)`
}

const NkLightStyles = {
  titleFont: `"SF UI Display", "Montserrat", sans-serif`,
  currentFont: `"Montserrat", sans-serif`,
  secondFont: `"DM Sans", monospace`,



  // make light/dark theme
  light: `rgb(10,5,15)`,
  lightAlpha: `rgba(10,5,15,0.4)`,
  lightGlass: `rgba(10,5,15,0.8)`,
  lightOver: `rgba(10,5,15,0.1)`,
  lightTrans: `rgba(10,5,15,0)`,

  softLight: `rgb(25,20,30)`,
  softLightAlpha: `rgba(25,20,30, 0.4)`,
  softLightGlass: `rgba(25,20,30, 0.8)`,
  softLightOver: `rgba(25,20,30, 0.1)`,
  softLightTrans: `rgba(25,20,30, 0)`,

  layerLight: `rgb(35,30,40)`,
  layerLightAlpha: `rgba(35,30,40, 0.4)`,
  layerLightGlass: `rgba(35,30,40, 0.8)`,
  layerLightOver: `rgba(35,30,40, 0.1)`,
  layerLightTrans: `rgba(35,30,40, 0)`,



  layerDark: `rgb(255, 255, 255)`,
  layerDarkAlpha: `rgba(255, 255,255,0.4)`,
  layerDarkGlass: `rgba(255, 255,255,0.8)`,
  layerDarkOver: `rgba(255, 255,255,0.1)`,
  layerDarkTrans: `rgba(255, 255,255,0)`,

  softDark: `rgb(245,240,250)`,
  softDarkAlpha: `rgba(245,240,250,0.4)`,
  softDarkGlass: `rgba(245,240,250,0.8)`,
  softDarkOver: `rgba(245,240,250,0.1)`,
  softDarkTrans: `rgba(245,240,250,0)`,

  dark: `rgb(225,220,230)`,
  darkAlpha: `rgba(225,220,230,0.4)`,
  darkGlass: `rgba(225,220,230,0.8)`,
  darkOver: `rgba(225,220,230,0.1)`,
  darkTrans: `rgba(225,220,230, 0)`,



  layerCardGradient: `linear-gradient(135deg, rgba(47,52,75, 0.5) 0%, rgba(41,45,66, 0.5) 100%)`,
  layerCardStroke: `rgba(160, 176, 255, 0.1)`,
  layerCardGradientGlass: `linear-gradient(135deg, rgba(47,52,75, 0.8) 0%, rgba(41,45,66, 0.8) 100%)`,

  layerCardGradientSuccess: `linear-gradient(135deg, rgba(85, 229, 255, 0.15) 0%, rgba(122, 255, 191, 0.15) 100%)`,
  layerCardStrokeSuccess: `rgba(160, 255, 176, 0.1)`,

  layerCardGradientDanger: `linear-gradient(135deg, rgba(255, 130, 113, 0.15) 0%, rgba(255, 98, 98, 0.15) 100%)`,
  layerCardStrokeDanger: `rgb(255, 98, 98, 0.1)`,



  // danger
  dangerColor: `rgb(255, 134, 134)`,
  dangerColorAlpha: `rgba(255, 134, 134, 0.4)`,
  dangerColorGlass: `rgba(255, 134, 134, 0.8)`,
  dangerColorOver: `rgba(255, 134, 134, 0.1)`,
  // warning
  warningColor: `rgb(255, 188, 87)`,
  warningColorAlpha: `rgba(255, 188, 87, 0.4)`,
  warningColorGlass: `rgba(255, 188, 87, 0.8)`,
  warningColorOver: `rgba(255, 188, 87, 0.1)`,
  // info
  infoColor: `rgb(85, 229, 255)`,
  infoColorAlpha: `rgba(85, 229, 255, 0.4)`,
  infoColorGlass: `rgba(85, 229, 255, 0.8)`,
  infoColorOver: `rgb(85, 229, 255, 0.1)`,
  // error
  errorColor: `rgb(255, 130, 113)`,
  errorColorAlpha: `rgba(255, 130, 113, 0.4)`,
  errorColorGlass: `rgba(255, 130, 113, 0.8)`,
  errorColorOver: `rgba(255, 130, 113, 0.1)`,
  // check
  checkColor: `rgb(122, 255, 191)`,
  checkColorAlpha: `rgba(122, 255, 191, 0.4)`,
  checkColorGlass: `rgba(122, 255, 191, 0.8)`,
  checkColorOver: `rgba(122, 255, 191, 0.1)`
}

export {
  NkDarkStyles,
  NkLightStyles
};