import { createGlobalStyle } from "styled-components";

import SfUiDisplayUltraLight from "../../assets/fonts/sf-ui-display/sf-ui-display-ultralight-58646b19bf205.woff"
import SfUiDisplayThin from "../../assets/fonts/sf-ui-display/sf-ui-display-thin-58646e9b26e8b.woff"
import SfUiDisplayLight from "../../assets/fonts/sf-ui-display/sf-ui-display-light-58646b33e0551.woff"
import SfUiDisplayMedium from "../../assets/fonts/sf-ui-display/sf-ui-display-medium-58646be638f96.woff"
import SfUiDisplaySemiBold from "../../assets/fonts/sf-ui-display/sf-ui-display-semibold-58646eddcae92.woff"
import SfUiDisplayBold from "../../assets/fonts/sf-ui-display/sf-ui-display-bold-58646a511e3d9.woff"
import SfUiDisplayHeavy from "../../assets/fonts/sf-ui-display/sf-ui-display-heavy-586470160b9e5.woff"
import SfUiDisplayBlack from "../../assets/fonts/sf-ui-display/sf-ui-display-black-58646a6b80d5a.woff"

import MontserratBlack from "../../assets/fonts/montserrat/Montserrat-Black.ttf"
import MontserratBlackItalic from "../../assets/fonts/montserrat/Montserrat-BlackItalic.ttf"
import MontserratBold from "../../assets/fonts/montserrat/Montserrat-Bold.ttf"
import MontserratBoldItalic from "../../assets/fonts/montserrat/Montserrat-BoldItalic.ttf"
import MontserratExtraBold from "../../assets/fonts/montserrat/Montserrat-ExtraBold.ttf"
import MontserratExtraBoldItalic from "../../assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf"
import MontserratExtraLight from "../../assets/fonts/montserrat/Montserrat-ExtraLight.ttf"
import MontserratExtraLightItalic from "../../assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf"
import MontserratItalic from "../../assets/fonts/montserrat/Montserrat-Italic.ttf"
import MontserratLight from "../../assets/fonts/montserrat/Montserrat-Light.ttf"
import MontserratLightItalic from "../../assets/fonts/montserrat/Montserrat-LightItalic.ttf"
import MontserratMedium from "../../assets/fonts/montserrat/Montserrat-Medium.ttf"
import MontserratMediumItalic from "../../assets/fonts/montserrat/Montserrat-MediumItalic.ttf"
import MontserratRegular from "../../assets/fonts/montserrat/Montserrat-Regular.ttf"
import MontserratSemiBold from "../../assets/fonts/montserrat/Montserrat-SemiBold.ttf"
import MontserratSemiBoldItalic from "../../assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf"
import MontserratThin from "../../assets/fonts/montserrat/Montserrat-Thin.ttf"
import MontserratThinItalic from "../../assets/fonts/montserrat/Montserrat-ThinItalic.ttf"

import DmSansRegular from "../../assets/fonts/dmsans/DMSans-Regular.ttf"
import DmSansItalic from "../../assets/fonts/dmsans/DMSans-Italic.ttf"
import DmSansMedium from "../../assets/fonts/dmsans/DMSans-Medium.ttf"
import DmSansMediumItalic from "../../assets/fonts/dmsans/DMSans-MediumItalic.ttf"
import DmSansBold from "../../assets/fonts/dmsans/DMSans-Bold.ttf"
import DmSansBoldItalic from "../../assets/fonts/dmsans/DMSans-BoldItalic.ttf"



const FontSfUiDisplayImport = createGlobalStyle`
  /* #### Generated By: http://www.cufonfonts.com #### */

  @font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 100;
    src: url(${SfUiDisplayUltraLight}) format('woff');
  }
    

  @font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 200;
    src: url(${SfUiDisplayThin}) format('woff');
  }
    

  @font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 300;
    src: url(${SfUiDisplayLight}) format('woff');
  }
    

  @font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 400;
    src: url(${SfUiDisplayMedium}) format('woff');
  }
    

  @font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 500;
    src: url(${SfUiDisplaySemiBold}) format('woff');
  }
    

  @font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 600;
    src: url(${SfUiDisplayBold}) format('woff');
  }
    

  @font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 700;
    src: url(${SfUiDisplayHeavy}) format('woff');
  }
    

  @font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 800;
    src: url(${SfUiDisplayBlack}) format('woff');
  }
`;



const FontMonserratImport = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratBlack});
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratBlackItalic});
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratBold});
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratBoldItalic});
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratExtraBold});
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratExtraBoldItalic});
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratExtraLight});
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratExtraLightItalic});
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratItalic});
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratLight});
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratLightItalic});
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratMediumItalic});
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratRegular});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratSemiBold});
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratSemiBoldItalic});
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratThin});
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src:url(${MontserratThinItalic});
    font-weight: 100;
    font-style: italic;
  }
`;



const FontDmSansImport = createGlobalStyle`
  @font-face {
    font-family: 'DM Sans';
    src: url(${DmSansRegular});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DmSansItalic});
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DmSansMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DmSansMediumItalic});
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DmSansBold});
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DmSansBoldItalic});
    font-weight: 700;
    font-style: italic;
  }
`;



export { FontSfUiDisplayImport, FontMonserratImport, FontDmSansImport };